import React, { useEffect, useState, useRef } from "react";
import { Fade } from "react-reveal";
import { Carousel } from 'react-responsive-carousel';
import moment from "moment";
import {Insight,Square ,People, News,UrlShare, BlogShare, FaceBook,Insta,Bubble} from '../resources/svg/blog.icon'

import { ReactComponent as Share_ } from '../resources/svg/Btn_share.svg';
import { ReactComponent as Blog_ } from '../resources/svg/Btn_blog.svg';
import { ReactComponent as Facebook_ } from '../resources/svg/Btn_facebook.svg';
import { ReactComponent as Insta_ } from '../resources/svg/Btn_insta.svg';

import $ from "jquery";
import * as config from '../config'

const Blog =()=>{

    const [data, setData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [clicked, setClicked] = useState([]);
    const [popImages, setPopImages] = useState([]);
    const [viewSize, setViewSize] = useState(window.innerWidth);
    const [copyIndex, setCopyIndex] = useState(-1);
        
    const styleClasses = ["Modal",openModal ? "ModalOpen":"ModalClose"]

    const popupWidth = 1920;
    const popupHeight = 1140;

    let horizon = (window.screen.width/2)-(popupWidth/2.5);
    let vertical = (window.screen.height/2)-(popupHeight/2);


     $(document).mouseup(function(e) {
         if($(".onShare").has(e.target.length === 0)) {
             $(".onShare").css("display","none")
         }
     }) 
    useEffect(()=> {
        fetch(config.POSPOT_LOG)
        .then (res => {
            return res.json();
        })
        .then (data => {
            data.data.map((key, index) => (
                key.id = index
                // data.put("id":index)
            ))
             setData(data.data)
        })
        .catch((err)=>
        console.log(err));
        
        $(".header").attr('style', 'background-color : #FFFFFF;');
    },[])
  
    // mobile 사이즈에서 left,middle,right 박스 없애고 순차로 블로그 리스트 쌓이게 220523 은정

    useEffect(()=>{
        headerPosition()
    },[viewSize, openModal])

    window.addEventListener('resize', function(){
        setViewSize(this.window.innerWidth)
    })

    $(document).ready(function() {
        if($(".menu").hasClass("active")) {
            $(".menu").removeClass("active");
            $("#close").css("display","none");
            $("#hamburger").css("display","block");
        }
    });

    // 모바일 화면 헤더에 가려지는 거 방지 220525 은정
    const headerPosition=()=>{

        if(viewSize<481) {
            if(openModal) {
                $(".header").css("z-index","0");
            }else {
                $(".header").css("z-index","50");
            }
        }
        
    }
    const openPop=(schedule)=> {
        // modal 창 열었을 떄 top으로 이동 220523 2은정
        window.scrollTo(0,0)
        document.body.style.overflow = "hidden";
        const list = [];
        if( schedule.img_path1 && schedule.img_path1.length > 0 ) {
            var img_info1 = {path : schedule.img_path1, width:schedule.width};
            list.push(img_info1);
        } 
            // list.push(data.img_path1);
        if( schedule.img_path2 && schedule.img_path2.length > 0 ) {
            var img_info2 = {path : data.img_path2, width:schedule.width};
            list.push(img_info2);
        } else {
                var img_info2 = {path : "", width:""};
        }
        if( schedule.img_path3 && schedule.img_path3.length > 0 ) {
            var img_info3 = {path : schedule.img_path3, width:schedule.width};
            list.push(img_info3);
        }else {
            var img_info3 = {path : "", width:""};
        }
        if( schedule.img_path4 && schedule.img_path4.length > 0 ) {
            var img_info4 = {path : schedule.img_path4, width:schedule.width};
            list.push(img_info4);
        }else {
            var img_info4 = {path : "", width:""};
        }
        if( schedule.img_path5 && schedule.img_path5.length > 0 ) {
            var img_info5 = {path : schedule.img_path5, width:schedule.width};
            list.push(img_info5);
        }else {
            var img_info5 = {path : "", width:""};
        }
        if( schedule.img_path6 && schedule.img_path6.length > 0 ) {
            var img_info6 = {path : schedule.img_path6, width:schedule.width};
            list.push(img_info6);
        }else {
            var img_info6 = {path : "", width:""};
        }
           
        setClicked(schedule);
        setOpenModal(!openModal);
        setPopImages(list)
    }
 // 모달창 떴을 때 스크롤방지 220530 은정
 function closeModal(){
    document.body.style.overflow = "unset";
    setOpenModal(false)            
}
    function viewlink2(numid, link) {
        $("#"+numid).attr("class","arrow3 show");
        copyToClipboard("#link-"+numid, link);

        setTimeout(function() {
            $(".arrow3").removeClass("show");
        },2000)

    }
    function copyToClipboard(node, val) {

            var i=i;
            var url = val;
            var textarea = document.createElement("textarea");
            document.body.appendChild(textarea);
            textarea.value = url;
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);
       
    }

    function viewlink3(numid, url) {

        // url 값이 없을 경우? 
        if(url===null) {
            alert("url이 존재하지 않습니다.")
            return;
        }

        $(".arrow4").attr("class","arrow4 show");
        // $("#"+numid).attr("class","arrow4 show");
        copyToClipboard("#link-"+numid, url);

        // url 복사 완료시 해당 창 자동 종료 22.05.03 은정
        setTimeout(function() {
            $(".arrow4").removeClass("show");
        },2000)
    }

    
    const Post=()=> {

        const getLogList = Object.entries(data).map((entrie) => {
            return entrie[1];
        });

      return (
    <Fade bottom>
        
       
        <div className={styleClasses.join(' ')} style={{
            top:horizon,
            left:vertical
        }}>
               <div className="logPopBack">

               {/* 모바일 */}
                             

                                <div className="logPop">

                                <div className="moHeader">
                                    <p className='mTitle'>포스팟 로그</p>
                                    <svg onClick={()=>closeModal()}
                                    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 4L20.0708 20.0708" stroke="#222222" strokeWidth="2.5"/>
                                        <path d="M20 4L3.92921 20.0708" stroke="#222222" strokeWidth="2.5"/>
                                    </svg>
                                </div>
                                
                                    <div className="pic">
                                        <div className="img">
                                            
                                        <Carousel 
                                            showThumbs={false}
                                            showStatus={false}                                            
                                            autoPlay={true} 
                                            
                                            // pixel에 따라서 여백주기
                                        >
                                        
                                             { popImages && popImages.map((img,index) => 
                                            img.path !== ''
                                            ?
                                            
                                            <div>
                                                {img.width < 1920 
                                                ?
                                                <img key={`img_path`+index} src={config.IMG_PATH+img.path} style={{
                                                    padding:`data.screenSize.width-16px`
                                                }}/> 
                                                :
                                                <img key={`img_path`+index} src={config.IMG_PATH+img.path} style={{
                                                    padding: "none",
                                                }}/>
                                                }                                            
                                            </div>
                                            :
                                            null
                                            ) }                                           
                                        </Carousel>
                                        </div>
                                       
                                        <div className="paging">
                                            <div className="active">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white"/>
                                                </svg>
                                            </div>
                                            <div className="etc">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white" fillOpacity="0.4"/>
                                                </svg>
                                            </div>
                                            <div className="etc">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white" fillOpacity="0.4"/>
                                                </svg>
                                            </div>
                                            <div className="etc">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white" fillOpacity="0.4"/>
                                                </svg>
                                            </div>
                                            <div className="etc">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white" fillOpacity="0.4"/>
                                                </svg>
                                            </div>
                                            <div className="etc">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white" fillOpacity="0.4"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p className="main-title">{clicked.title}</p>
                                        <p className="desc">{clicked.content}</p>
                                        <p className="info">{moment(clicked.create_date).format('YYYY.MM.DD')}<span>|</span>{clicked.category_name}</p>
                                      

                                    {/* 블로그 상세보기 링크 복사 22.05.03 은정 */}

                                    <ul className="linkToSocial">
                                        {clicked.link !== null ?
                                        <li className="LinksocialList" value="1" onClick={()=>viewlink3("copytxt3"+copyIndex, clicked.link)}><Share_/></li>
                                        :
                                        null }
                                        {clicked.blog_link === 0 ?
                                        null :
                                        <a href="https://blog.naver.com/pospot0911" target="_blank"><li className="LinksocialList"><Blog_/></li></a>
                                        }
                                        {clicked.facebook_link === 0 ?
                                        null :
                                        <a href="https://www.facebook.com/pospot.kr" target="_blank"><li className="LinksocialList"><Facebook_/></li></a>
                                        }
                                        {clicked.instagram_link === 0 ?
                                        null :
                                        <a href="https://www.instagram.com/pospot_official" target="_blank"><li className="LinksocialList"><Insta_/></li></a>
                                        }

                                        <div className="arrow4" id={"copytxt"+copyIndex}>
                                            <div className="window">
                                                <div className="arrow-box4">
                                                    <p>콘텐츠 링크가 복사되었어요.<br/>원하는 곳에 붙여넣으세요.</p>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </ul>
                                   
                                        

                                        <button id="closeModal" className="closeBtn" onClick={()=>closeModal()}>
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 4L20.0708 20.0708" stroke="white" strokeWidth="2.5"/>
                                            <path d="M20 4L3.92921 20.0708" stroke="white" strokeWidth="2.5"/>
                                        </svg>
                                    </button>
                                    
                                    </div>
                                    
                                </div>

</div>
</div>
    </Fade> 
    )
    }

    return (

        
        <Fade bottom>
            <div className="blogWrapper">
                <div className="post" >

                <div className="pageInfo">
                    <div className="pageInfoTitle">포스팟 로그</div>
                    <div className="pageInfoDetail">포스팟의 다양한<br />스토리를 담은 공간</div>
                    <div className="pageInfoDeco">POSPOT LOG</div>
                </div>

                <div className="pageContent">
                    <div className="pageContenSide">
                        {viewSize > 480 
                            ?
                            <>
                            <div className="postWrapperLeft">
                            {data
                            .filter((schedule)=> schedule.id%2 === 0)
                            .map((schedule, index)=> {
                                return (
                                <div className="singlePostWrapper" key={index}>
                                    <div className="postImg" name="이름">
                                        <img onClick={(e)=>{
                                            openPop(schedule);
                                        }}src={`https://apipospot.anypot.co.kr/${schedule.img_path1}`} name={schedule.id}></img>
                                    </div>
        
                                    <div className="postContentInfo">
                                        <div className="postContent">
                                            <h2 className="postTitle">{schedule.title}</h2>
                                            <div className="postDetail">{schedule.content}</div>
                                        </div>
                                    <div className="postInfo">
                                        <div className="postDate">{moment(schedule.modify_date).format("YYYY.M.DD")}</div>
                                        <div className="division" style={{
                                            padding:"0 7px"
                                        }}>{` | `}</div>
                                        <div className="postCategory">{schedule.category_name}</div>
                                    </div>
                                        <div className="linkList">
                                        {schedule.link !== null ?
                                        <div className="linkIcon" onClick={viewlink3}><Share_/></div>
                                        :
                                        null
                                        }
                                        {schedule.instgram_link === 1 && <a href="https://www.instagram.com/pospot_official" target={"_blank"}><Insta_/></a>}
                                        {schedule.facebook_link === 1 && <a href="https://www.facebook.com/pospot.kr" target={"_blank"}><Facebook_/></a>}
                                        {schedule.blog_link === 1 && <a href="https://blog.naver.com/pospot0911" target={"_blank"}><Blog_/></a>}
                                        </div>

                                        <div className="arrow2" id={"copytxt"+index} style={{position:"absolute",zIndex:"1"}}>
                                                        <div className="window">
                                                            <div className="arrow-box2">
                                                                <p className="link" id={"link-copytxt2"+index}></p>
                                                                {/* {this.state.logData[index].link} {this.state.cutLink}*/}
                                                                <p className="purple copy" onClick={()=>viewlink2("copytxt2"+index, this.state.logData[index].link)}>복사</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="arrow3" id={"copytxt2"+index}>
                                                        <div className="window">
                                                            <div className="arrow-box3">
                                                                <p>콘텐츠 링크가 복사되었어요.<br/>원하는 곳에 붙여넣으세요.</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                        </div>
        
                                        <div className="category">
                                            {schedule.category_id==="insight" ? 
                                                <Insight /> : 
                                                schedule.category_id==="people" ?
                                                <People /> :
                                                schedule.category_id==="news" ?
                                                <News /> :
                                                <Square />
                                            }
                    
                                        </div>
                                        </div>
                                    ) } )}


                                    
                                    </div>
                                    <div className="postWrapperMiddle"></div>
                                    <div className="postWrapperRight">
                                    {data
                                    .filter((schedule)=> schedule.id%2 ===1)
                                    .map((schedule, index)=>{
                
                                        return (
                                        <div className="singlePostWrapper" key={index}>
                                            <div className="postImg" >
                                                <img onClick={(e)=>{
                                                    openPop(schedule);
                                                }}src={`https://apipospot.anypot.co.kr/${schedule.img_path1}`} name={schedule.id}></img>
                                            </div>
        
                                        <div className="postContentInfo">
                                            <div className="postContent">
                                                <h2 className="postTitle">{schedule.title}</h2>
                                                <div className="postDetail">{schedule.content}</div>
                                            </div>
                                        <div className="postInfo">
                                            <div className="postDate">{moment(schedule.modify_date).format("YYYY.M.DD")}</div>
                                            <div className="division" style={{
                                                padding:"0 7px"
                                            }}>{` | `}</div>
                                            <div className="postCategory">{schedule.category_name}</div>
                                        </div>
                                        <div className="linkList">
                                        {schedule.link !== null ?
                                        <div className="linkIcon"><Share_ /></div>
                                        :
                                        null
                                        }
                                        {schedule.instgram_link === 1 && <a href="https://www.instagram.com/pospot_official" target={"_blank"}><Insta_/></a>}
                                        {schedule.facebook_link === 1 && <a href="https://www.facebook.com/pospot.kr" target={"_blank"}><Facebook_/></a>}
                                        {schedule.blog_link === 1 && <a href="https://blog.naver.com/pospot0911" target={"_blank"}><Blog_/></a>}
                                        </div>
                                        </div>
        
                                        <div className="category">
                                            {schedule.category_id==="insight" ? 
                                                <Insight /> : 
                                                schedule.category_id==="people" ?
                                                <People /> :
                                                schedule.category_id==="news" ?
                                                <News /> :
                                                <Square />
                                            }
                                         </div>
                                        </div>

        
                                    )})}
                                    </div>
                                    </>
                                    
                               
                                : 
                                <div className="postMobileWrapper">
                                    {data.map((schedule, index)=>{
                
                                        return (
                                        <div className="singlePostWrapper" key={index}>
                                            <div className="postImg" >
                                                <img onClick={(e)=>{
                                                    openPop(schedule);
                                                    setCopyIndex(index);
                                                }}src={`https://apipospot.anypot.co.kr/${schedule.img_path1}`} name={schedule.id}></img>
                                            </div>
        
                                        <div className="postContentInfo">
                                            <div className="postContent">
                                                <h2 className="postTitle">{schedule.title}</h2>
                                                <div className="postDetail">{schedule.content}</div>
                                            </div>
                                        <div className="postInfo">
                                            <div className="postDate">{moment(schedule.modify_date).format("YYYY.M.DD")}</div>
                                            <div className="division" style={{
                                                padding:"0 7px"
                                            }}>{` | `}</div>
                                            <div className="postCategory">{schedule.category_name}</div>
                                        </div>
                                        <div className="linkList">
                                        {schedule.link !== null ?
                                        <div className="linkIcon" onClick={()=>viewlink2("copytxt2"+index,schedule.link)}><Share_/></div>
                                        
                                        :
                                        null
                                        }
                                        {schedule.instgram_link === 1 && <a href={schedule.link} target={"_blank"}><Insta_/></a>}
                                        {schedule.facebook_link === 1 && <a href={schedule.FaceBook} target={"_blank"}><Facebook_/></a>}
                                        {schedule.blog_link === 1 && <a href={schedule.blog_link} target={"_blank"}><Blog_/></a>}
                                        </div>
                                        
                                        </div>
        
                                        <div className="category">
                                            {schedule.category_id==="insight" ? 
                                                <Insight /> : 
                                                schedule.category_id==="people" ?
                                                <People /> :
                                                schedule.category_id==="news" ?
                                                <News /> :
                                                <Square />
                                            }
                                         </div>

                                         <div className="arrow3" id={"copytxt2"+index}>
                                                        <div className="window">
                                                            <div className="arrow-box3">
                                                                <p>콘텐츠 링크가 복사되었어요.<br/>원하는 곳에 붙여넣으세요.</p>
                                                            </div>
                                                        </div>
                                                    </div>


                                        </div>

        
                                    )})}
                                    </div>
                                }
                            </div>

                            


                            {openModal ? 
                            <Post /> 
                            : null} 

                    </div>
                </div>
                </div> 
        </Fade>
    )
}

export default Blog;