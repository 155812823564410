/* eslint-disable */
import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Fade } from "react-reveal";

import moment from 'moment';
import { ReactComponent as History2 } from '../resources/svg/img2.svg';

import { ReactComponent as Img1 } from '../resources/svg/Group 511.svg';
import { ReactComponent as Img2 } from '../resources/svg/Group 522.svg';
import { ReactComponent as Img3 } from '../resources/svg/Group 533.svg';
import { ReactComponent as Img4 } from '../resources/svg/Group 544.svg';
import { ReactComponent as Img5 } from '../resources/svg/Group 555.svg';
import { ReactComponent as Img6 } from '../resources/svg/Group 516.svg';
import { ReactComponent as Img7 } from '../resources/svg/Group 527.svg';
import { ReactComponent as Img8 } from '../resources/svg/Group 538.svg';
import { ReactComponent as Img9 } from '../resources/svg/Group 549.svg';
import { ReactComponent as Img10 } from '../resources/svg/Group 55.svg';
import { ReactComponent as Img11 } from '../resources/svg/Group 5611.svg';
// category
import { ReactComponent as News } from '../resources/svg/News2.svg';
import { ReactComponent as People } from '../resources/svg/People2.svg';
import { ReactComponent as Square } from '../resources/svg/Square2.svg';
import { ReactComponent as Insight } from '../resources/svg/Insight2.svg';

// link
import { ReactComponent as Share } from '../resources/svg/Btn_share.svg';
import { ReactComponent as Blog } from '../resources/svg/Btn_blog.svg';
import { ReactComponent as Facebook } from '../resources/svg/Btn_facebook.svg';
import { ReactComponent as Insta } from '../resources/svg/Btn_insta.svg';

// main
import { ReactComponent as Gotgam1 } from '../resources/svg/Gotgam1.svg';
import { ReactComponent as Gotgam2 } from '../resources/svg/Gotgam2.svg';
import { ReactComponent as Gotgam3 } from '../resources/svg/Gotgam3.svg';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import $ from 'jquery';


import * as config from '../config'
// import $ from 'jquery';

const Main=()=>{

    const [logData, setLogData] = useState([]);
    const [popupShow, setPopupShow] = useState(false);
    const [popData, setPopData] = useState([]);
    const [popImages, setPopImages] = useState([]);
    const [viewHeight, setViewHeight] = useState($(window).scrollTop())
    const [copyIndex, setCopyIndex] = useState(-1);

    const styleClasses = ["Modal",popupShow ? "ModalOpen":"ModalClose"]

    const popupWidth = 1920;
    const popupHeight = 1140;

    let horizon = (window.screen.width/2)-(popupWidth/2.5);
    let vertical = (window.screen.height/2)-(popupHeight/2);


    useEffect(()=>{
        fetch(config.POSPOT_LOG)
        .then (res =>{
            return res.json();
        })
        .then(data => {
            setLogData(data.data)
            setPopupShow(false)
        })
        .catch((err)=>{
            console.log(err)
        })
    },[])

    // scroll 높이에 따라 header 색상 변경 220523 은정

    useEffect(()=>{
        changeHeaderColor();
    },[viewHeight, popupShow])


    
        const getLogList = Object.entries(logData).map((entrie) => {
            return entrie[1];
        });
        
  
    window.addEventListener('scroll',function() {
        let tempValue = $(this.window).scrollTop()
        setViewHeight(tempValue)
    })
    // 헤더로 옮기기 220523 은정
    window.addEventListener('locationchange', function(){
        let tempPage = window.location.href
        setCurrentPage(tempPage)
    })


    const changeHeaderColor=()=>{
       
        let tempTag = $(".spacer") 
        if(tempTag.length) {
        let spaceroffset = $(".spacer").offset().top;
        let spacerheight = $("#txt1section").height()
        let txt2section = $("#txt2section").offset().top;
        let txt3section = $("#txt3section").offset().top;
        let txt4section = $("#txt4section").offset().top;
        let txt5section = $("#txt5section").offset().top;
        let txt6section = $("#txt6section").offset().top;
      

       

       //  mobile 헤더 색상 변경 22.05.16 2은정
       if(window.innerWidth <= 480 && !popupShow) {
            if(viewHeight < spacerheight) {
                $(".header").attr('style', 'background-color : #EAECF9;');
            }

            if(parseInt(spaceroffset) < parseInt(viewHeight) ) {
                $(".header").attr('style', 'background-color : #FFFFFF;');
            }
            
            if(parseInt(txt2section-60) < parseInt(viewHeight) ) {
                $(".header").attr('style', 'background-color : #EAECF9;');
            }
            if(parseInt(txt4section-860) < parseInt(viewHeight) ) {
                $(".header").attr('style', 'background-color : #FFFFFF;');
            }
        }
       
        if(window.innerWidth > 480 && !popupShow){
      
           if(viewHeight < spacerheight) {
               $(".header").attr('style', 'background-color : #EAECF9;');
              $("#txt1section .section2_desc p:last-child").attr('style','display : none; margin-top: 0px; opacity: 0;') 
              $("#txt1section .section2_desc p:first-child").attr('style','display : block; margin-top: 300px; opacity:1;')
              $(".top-img").attr('style','left:7%')
              $(".bottom-img").attr('style','left:-120px;')
           }


           // 수어 이미지 변형 추가 22.05.03 은정
           
           if(parseInt(spaceroffset) < parseInt(viewHeight) ) {
                $(".header").attr('style', 'background-color : #FFFFFF;');
               $("#txt1section .section2_desc p:first-child").attr('style','display : none; margin-top: 0px opacity: 0;') 
               $("#txt1section .section2_desc p:last-child").attr('style','display : block; margin-top: 300px; opacity:1;')
               $(".top-img").attr('style','left:0%')
               $(".bottom-img").attr('style','left:0px;') 
            }

           if(parseInt(txt2section-100) < parseInt(viewHeight) ) {
               $(".header").attr('style', 'background-color : #EAECF9;');
               $("#txt3section .imgDiv3 svg").attr('style','transform : rotate(0deg);') 
               $("#txt3section .section2_desc p:first-child").attr('style','display : none; margin-top: 0px; opacity: 0;') 
               $("#txt3section .section2_desc p:last-child").attr('style','display : block; margin-top: 300px; opacity:1;')
                
           }

           if(parseInt(txt3section-100) < parseInt(viewHeight) ) {                
               $(".header").attr('style', 'background-color : #FFFFFF;');
               $("#txt3section .imgDiv3 svg").attr('style','transform : rotate(-45deg);') 
               $("#txt3section .section2_desc p:last-child").attr('style','display : none; margin-top: 0px; opacity: 0;') 
               $("#txt3section .section2_desc p:first-child").attr('style','display : block; margin-top: 300px; opacity:1;') 
               
           }
                   
           if(parseInt(txt3section-300) < parseInt(viewHeight) ) {                
           
               $("#fl1").fadeOut(500, function() {
                   $("#fl2").show()
                   
              });
             
           }

           if(parseInt(txt4section-100) < parseInt(viewHeight) ) {                
               $(".header").attr('style', 'background-color : #FFFFFF;');
               
           }
           
           if(parseInt(txt6section-100) < parseInt(viewHeight) ) {                
               $(".header").attr('style', 'background-color : #FFFFFF;');
               
           }
       }if(window.innerWidth <= 480 && popupShow) {
           setTimeout(() => {
            $(".header").css("z-index","0");
           }, 900);
           
        }if(window.innerWidth > 480 && popupShow) {
            $(".header").css("z-index","50");
            
        }
   }
   
    }
    const openPop=(data)=> {
        
        
        document.body.style.overflow = "hidden";
        const list = [];
        if( data.img_path1 && data.img_path1.length > 0 ) {
            var img_info1 = {path : data.img_path1, width:data.width};
            list.push(img_info1);
        } 
            // list.push(data.img_path1);
        if( data.img_path2 && data.img_path2.length > 0 ) {
            var img_info2 = {path : data.img_path2, width:data.width};
            list.push(img_info2);
        } else {
                var img_info2 = {path : "", width:""};
        }
        if( data.img_path3 && data.img_path3.length > 0 ) {
            var img_info3 = {path : data.img_path3, width:data.width};
            list.push(img_info3);
        }else {
            var img_info3 = {path : "", width:""};
        }
        if( data.img_path4 && data.img_path4.length > 0 ) {
            var img_info4 = {path : data.img_path4, width:data.width};
            list.push(img_info4);
        }else {
            var img_info4 = {path : "", width:""};
        }
        if( data.img_path5 && data.img_path5.length > 0 ) {
            var img_info5 = {path : data.img_path5, width:data.width};
            list.push(img_info5);
        }else {
            var img_info5 = {path : "", width:""};
        }
        if( data.img_path6 && data.img_path6.length > 0 ) {
            var img_info6 = {path : data.img_path6, width:data.width};
            list.push(img_info6);
        }else {
            var img_info6 = {path : "", width:""};
        }
        setPopupShow(true)
        setPopData(data)
        setPopImages(list)
        window.scrollTo(0,0);
        
        
    }
        //페이지 이동시 자동 메뉴바 꺼짐 대웅
        $(document).ready(function() {
            if($(".menu").hasClass("active")) {
                $(".menu").removeClass("active");
                $("#close").css("display","none");
                $("#hamburger").css("display","block");
            }
        });

        // 포스팟로그 링크버튼 말풍선
        $(".header, .container, .footer").on('click',function(e){
            if(!$(e.target).hasClass('linkIcon')){
                arrowClose(2);
            }
            if(!$(e.target).hasClass('copy')){
                arrowClose(3);
            }
        });

        $(".arrow2:first-of-type .copy").on('click',function(){
            let link = $(".link").attr('href');
            copyToClipboard(link);
            arrowClose(2);
            arrowShow(3);
        });
        // 모달창 떴을 때 스크롤방지 220530 은정
        function closeModal(){
            document.body.style.overflow = "unset";
            setPopupShow(false)            
        }
        // 말풍선 닫기
        function arrowClose(num) {
            $(".arrow"+num).removeClass("show");
        }
        function viewlink(numid, link) {
            $("#copytxt"+numid).attr("class","arrow2 show");
            $("#link-copytxt2"+numid).text(checkMaxString(link));
        }

        // 포스팟로그 링크 말풍선 글자수 제한 1은정
        function checkMaxString(obj) {
            var str_len = obj.length;
            var byte = 0;
            var len = 0;
            var one_char = "";
            var str2 = "";
            for(var j = 0; j < str_len; j++) { 
                one_char = obj.charAt(j); 
                if(escape(one_char).length > 4) { 
                    byte += 2; 
                } else { 
                    byte++; 
                }
                if(byte <= 30) { 
                    len = j+1; 
                } 
            } 
            if(byte > 30) { 
                str2 = obj.substr(0, len)+"..."; 
            } else { 
                str2 = obj; 
            } 
            
            return str2;
        }

        // 링크 복사 1은정
        function viewlink2(numid, link) {
            
            $("#"+numid).attr("class","arrow3 show");
            copyToClipboard("#link-"+numid, link);
            setTimeout(function() {
                $(".arrow3").removeClass("show");
            },2000)
        }

        function copyToClipboard(node, val) {

                var i=i;
                var url = val;
                var textarea = document.createElement("textarea");
                document.body.appendChild(textarea);
                textarea.value = url;
                textarea.select();
                document.execCommand("copy");
                document.body.removeChild(textarea);
           
        }

        function viewlink3(numid, url) {

            // url 값이 없을 경우? 
            if(url===null) {
                alert("url이 존재하지 않습니다.")
                return;
            }
            $(".arrow4").attr("class","arrow4 show");
            // $("#"+numid).attr("class","arrow4 show");
            copyToClipboard("#link-"+numid, url);
    
            // url 복사 완료시 해당 창 자동 종료 22.05.03 은정
            setTimeout(function() {
                $(".arrow4").removeClass("show");
            },2000)
        }
    

        const Log=()=>{
            
            return (
                <Fade bottom>
                    
                    <div className={styleClasses.join(' ')} style={{
            top:horizon,
            left:vertical
        }}>

<div className="logPopBack">
                <div className="logPop">
                                <div className="moHeader">
                                    <p className='mTitle'>포스팟 로그</p>
                                    <svg onClick={()=>closeModal()}
                                    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 4L20.0708 20.0708" stroke="#222222" strokeWidth="2.5"/>
                                        <path d="M20 4L3.92921 20.0708" stroke="#222222" strokeWidth="2.5"/>
                                    </svg>
                                </div>
                                
                                    <div className="pic">
                                        <div className="img">
                                            
                                        <Carousel 
                                            showThumbs={false}
                                            showStatus={false}                                            
                                            autoPlay={true} 
                                            
                                            // pixel에 따라서 여백주기
                                        >
                                        
                                             { popImages && popImages.map((img,index) => 
                                            img.path !== ''
                                            ?
                                            
                                            <div>
                                                {img.width < 1920 
                                                ?
                                                <img key={`img_path`+index} src={config.IMG_PATH+img.path} style={{
                                                    padding:`data.screenSize.width-16px`
                                                }}/> 
                                                :
                                                <img key={`img_path`+index} src={config.IMG_PATH+img.path} style={{
                                                    padding: "none",
                                                }}/>
                                                }                                            
                                            </div>
                                            :
                                            null
                                            ) }                                           
                                        </Carousel>
                                        </div>
                                       
                                        <div className="paging">
                                            <div className="active">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white"/>
                                                </svg>
                                            </div>
                                            <div className="etc">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white" fillOpacity="0.4"/>
                                                </svg>
                                            </div>
                                            <div className="etc">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white" fillOpacity="0.4"/>
                                                </svg>
                                            </div>
                                            <div className="etc">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white" fillOpacity="0.4"/>
                                                </svg>
                                            </div>
                                            <div className="etc">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white" fillOpacity="0.4"/>
                                                </svg>
                                            </div>
                                            <div className="etc">
                                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3" cy="3" r="3" fill="white" fillOpacity="0.4"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p className="main-title">{popData.title}</p>
                                        <p className="desc">{popData.content}</p>
                                        <p className="info">{moment(popData.create_date).format("YYYY-MM-DD")}<span>|</span>{popData.category_name}</p>
                                      

                                    {/* 블로그 상세보기 링크 복사 22.05.03 은정 */}

                                    <ul className="linkToSocial">
                                        {popData.link !== null ?
                                        <li className="LinksocialList" value="1" onClick={()=>viewlink3("copytxt2"+copyIndex,popData.link)}><Share/></li>
                                        :
                                        null }
                                        {popData.blog_link === 0 ?
                                        null :
                                        <a href="https://blog.naver.com/pospot0911" target="_blank"><li className="LinksocialList"><Blog/></li></a>
                                        }
                                        {popData.facebook_link === 0 ?
                                        null :
                                        <a href="https://www.facebook.com/pospot.kr" target="_blank"><li className="LinksocialList"><Facebook/></li></a>
                                        }
                                        {popData.instagram_link === 0 ?
                                        null :
                                        <a href="https://www.instagram.com/pospot_official" target="_blank"><li className="LinksocialList"><Insta/></li></a>
                                        } 
                                    
                                    </ul>
                                   
                                    <div className="arrow4" id={"copytxt"+copyIndex}>
                                            <div className="window">
                                                <div className="arrow-box5">
                                                    <p>콘텐츠 링크가 복사되었어요.<br/>원하는 곳에 붙여넣으세요.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <button id="closeModal" className="closeBtn" onClick={()=>closeModal()}>
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 4L20.0708 20.0708" stroke="white" strokeWidth="2.5"/>
                                            <path d="M20 4L3.92921 20.0708" stroke="white" strokeWidth="2.5"/>
                                        </svg>
                                    </button>
                                    
                                    </div>
                                    
                                </div>
                                </div>
                                </div>
                                </Fade>
            )
        }
       
        return(
            <Fade >
            <div className='Main'>
                <div className="container">
                    <div className="main">
                        <div className="contents wide"> 

                            <div className="section1">
                            <div className="copyDiv-mo">
                                    <p className="main-title">그때 그곳의<br/>감성을 전하세요</p>
                                    <p className="sub-title">위치기반 SNS 플랫폼, [곳;감]</p>
                                </div>
                            <div className="imgDiv">
                                    <div className="box"><Gotgam1/></div>
                                    <div className="box rev"><Gotgam2/></div>
                                    <div className="box"><Gotgam3/></div>
                                </div>
                                <div className="copyDiv">
                                    <p className="main-title">그때 그곳의 감성을 전하세요</p>
                                    <p className="sub-title">위치기반 SNS 플랫폼, [곳;감]</p>
                                </div>
                            </div>

                            <div id="trigger" className='spacer'></div>

                            <div className="section2 scroll moHidden"  id="txt1section">
                                <div className="section2_desc scroll" id="parasec1">
                                    <p className="disc1">추억의 장소를 어떻게 기억하시나요?<br/>감성적인 경험은 그때 그곳을 특별하게 만듭니다.<br/>그리고 추억은 우리를 앞으로 나아가게 만들죠.</p>
                                    <p className='disc2' style={{display:"none"}}>포스팟은 장소의 추억을 저장하는 서비스를 만들기로 했습니다.<br/>[곳;감]은 블록체인 기술로 감성까지 전달하는<br/>위치기반 감성 SNS 플랫폼입니다.</p>
                                </div>
                                <div className="imgDiv">
                                    <div className='top-img'><Img1 /><Img2 /><Img3 /><Img4 /><Img5 /><svg /></div>
                                    <div className='bottom-img'><Img6 /><Img7 /><Img8 /><Img9 /><Img11 /><Img10 /></div>
                                </div>
                            </div>

                            {/* 대웅 */}
                            <div className="section2-mo"  id="txt1section-mo">
                                <div className="copyDiv-mo " id="parasec1">
                                    <p className="disc">추억의 장소를<br/>어떻게 기억하시나요?<br/>감성적인 경험은 그때 그곳을<br/>특별하게 만듭니다.<br/>그리고 추억은 우리를<br/>앞으로 나아가게 만들죠.</p>
                                </div>
                                <div className="imgDiv imgDiv2" >
                                <History2/>
                                </div>
                                <div className="copyDiv2-mo " id="parasec2">
                                    <p className='disc2'>포스팟은 장소의 추억을 저장하는<br/>서비스를 만들기로 했습니다.<br/>[곳;감]은 블록체인 기술로<br/>감성까지 전달하는<br/>위치기반 감성 SNS 플랫폼입니다.</p>
                                </div>
                            </div>

                            <div className="section1" id="txt2section">
                                <div className="copyDiv-mo mt-84">
                                    <p className="main-title">AI 기술로<br/>언어의 장벽을 넘겠습니다</p>
                                    <p className="sub-title">수어 실시간 통역 서비스</p>
                                </div>
                                <div className="ani"  id="ani">
                                    <svg width="859" height="521" viewBox="0 0 859 521" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M617 120.65H217C106.543 120.65 17 210.193 17 320.65C17 431.107 106.543 520.65 217 520.65H617C727.457 520.65 817 431.107 817 320.65C817 210.193 727.457 120.65 617 120.65Z" fill="#E8A645"/>
                                        <mask id="mask0_582_5333" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="17" y="0" width="800" height="521">
                                        <path d="M617 0H217C106.543 0 17 116.63 17 260.5C17 404.37 106.543 521 217 521H617C727.457 521 817 404.37 817 260.5C817 116.63 727.457 0 617 0Z" fill="#E8A645"/>
                                        </mask>
                                        <g mask="url(#mask0_582_5333)">
                                        <path d="M355.441 145.15C355.441 145.15 348.761 108.13 360.621 85.24C375.521 56.46 418.511 43.55 454.351 71.22C479.691 90.78 465.681 148.82 465.681 148.82L390.911 117.03L355.451 145.15H355.441Z" fill="#23130F"/>
                                        <path d="M438.591 252.63L411.881 278.02L385.171 252.63L389.871 195.09H433.901L438.591 252.63Z" fill="#FFCEC9"/>
                                        <path d="M364.821 138.64C364.821 138.64 358.501 134.56 353.611 141.07C348.721 147.59 351.971 172.04 364.201 172.04C376.431 172.04 364.821 138.64 364.821 138.64Z" fill="#FFCEC9"/>
                                        <path d="M459.33 138.64C459.33 138.64 465.65 134.56 470.54 141.07C475.43 147.59 472.17 172.04 459.95 172.04C447.73 172.04 459.33 138.64 459.33 138.64Z" fill="#FFCEC9"/>
                                        <path d="M461.37 131.85C461.37 104.42 439.22 86.47 411.88 86.47C384.54 86.47 362.38 104.42 362.38 131.85C362.38 131.85 360.23 166.84 360.23 168.54C360.23 194.6 392.14 227.66 411.88 227.66C434.67 227.66 463.52 188.28 463.52 168.54C463.52 166.84 461.36 131.85 461.36 131.85H461.37Z" fill="#FFCEC9"/>
                                        <path d="M450.301 34.0001C450.961 53.7501 436.481 66.5701 414.821 67.3001C393.151 68.0201 382.851 55.4501 382.201 35.7001C381.541 15.9501 392.481 0.750072 414.131 0.0300721C435.791 -0.689928 449.641 14.2601 450.301 34.0101V34.0001Z" fill="#23130F"/>
                                        <path d="M422.08 133.06C422.08 133.06 442.461 131.03 446.391 131.41C452.941 132.04 454.34 136.29 455.36 138.6C455.71 139.39 428.75 142.03 424.83 140.61C420.91 139.19 422.08 133.06 422.08 133.06Z" fill="#23130F"/>
                                        <path d="M401.75 133.06C401.75 133.06 381.37 131.03 377.44 131.41C370.89 132.04 369.48 136.29 368.47 138.6C368.12 139.39 395.08 142.03 399 140.61C402.92 139.19 401.75 133.06 401.75 133.06Z" fill="#23130F"/>
                                        <path d="M386.8 177.44C376.28 177.44 367.73 168.89 367.73 158.37C367.73 147.85 376.29 139.3 386.8 139.3C397.31 139.3 405.87 147.85 405.87 158.37C405.87 168.89 397.31 177.44 386.8 177.44ZM386.8 141.18C377.32 141.18 369.61 148.89 369.61 158.37C369.61 167.85 377.32 175.55 386.8 175.55C396.28 175.55 403.99 167.84 403.99 158.37C403.99 148.9 396.28 141.18 386.8 141.18Z" fill="#45299A"/>
                                        <path d="M437.15 177.44C426.63 177.44 418.08 168.89 418.08 158.37C418.08 147.85 426.63 139.3 437.15 139.3C447.67 139.3 456.22 147.85 456.22 158.37C456.22 168.89 447.67 177.44 437.15 177.44ZM437.15 141.18C427.67 141.18 419.96 148.89 419.96 158.37C419.96 167.85 427.67 175.55 437.15 175.55C446.63 175.55 454.34 167.84 454.34 158.37C454.34 148.9 446.63 141.18 437.15 141.18Z" fill="#45299A"/>
                                        <path d="M419.031 157.43H404.931V159.31H419.031V157.43Z" fill="#45299A"/>
                                        <path d="M403.371 189.04H421.991L412.681 192.62L403.371 189.04Z" fill="#D77D79"/>
                                        <path d="M411.581 280.15C437.381 280.15 449.981 254.74 443.931 252.93C426.841 247.81 414.191 244.71 414.191 244.71C414.191 244.71 399.731 247.99 380.201 253.99C373.501 256.05 386.501 280.15 411.581 280.15Z" fill="#FFCEC9"/>
                                        <path d="M576.3 401.73C574.28 392.24 572.1 383.77 569.81 376.91C567.82 370.96 565.39 363.87 562.6 356.28C554.5 334.3 543.3 308.08 530.61 292.72C520.34 280.29 470.9 261.93 443.93 252.93C438.69 277.62 386.16 277.66 380.2 254C356.41 262.48 308.91 281.08 292.78 294.85C275.73 309.39 264.5 334.02 257.17 357.11C252.98 370.32 250.06 383.02 248.07 393.04C246.16 402.61 245.09 409.74 244.55 412.54C238.01 446.16 216.04 490.51 219.27 510C227.77 561.42 249.56 561.42 249.56 561.42L587.04 561.28C596.04 554.3 602.9 528.12 602.99 510C603.13 481.33 585.28 443.76 576.31 401.72L576.3 401.73Z" fill="#45299A"/>
                                        <path d="M496.52 387.37H321.95V407.16H496.52V387.37Z" fill="white"/>
                                        <path d="M496.52 430.24H321.95V450.03H496.52V430.24Z" fill="white"/>
                                        <path d="M358.23 375.4C358.23 375.4 366.9 366.36 367.05 366.06C367.24 365.68 367.62 365.24 367.6 364.59L367.8 349.17C367.8 349.17 368.15 346.02 365.72 345.42L365.67 336.92C365.49 332.14 364.47 331.27 362.39 330.85C362.39 330.85 363.77 321.3 360.43 320.93C357.95 320.65 332.21 326.48 332.21 326.48L350.22 294.58C352.41 289.04 350.72 283.99 347.82 283.4C345.74 282.98 342.29 286.54 340.33 288.81C337.82 291.7 307.44 328.55 306.61 329.8C305.28 331.55 304.47 335.32 304.94 337.29C304.94 338.54 320.49 383.93 320.49 383.93L268.12 451.5L298.28 484.06L356.57 392.05C359.07 388.3 359.07 380.39 358.24 375.4H358.23Z" fill="#FFCEC9"/>
                                        <path d="M362.651 327.85L339.021 340.04L362.541 330.88C362.491 330.87 362.441 330.86 362.391 330.85C362.391 330.85 362.581 329.53 362.651 327.85Z" fill="#D77D79"/>
                                        <path d="M346.45 354.25L365.72 345.42L365.7 341.15L346.45 354.25Z" fill="#D77D79"/>
                                        <path d="M361.44 371.84C361.29 372.13 358.22 375.39 358.22 375.39L353.7 365.05L362.05 359.88C362.18 362.61 362.87 368.32 361.44 371.83V371.84Z" fill="#D77D79"/>
                                        <path d="M344.47 418.99C344.47 418.99 299.55 553.23 294.36 567.01L322.98 574.66L344.47 418.99Z" fill="#2C1A62"/>
                                        <path d="M294.38 566.99C303 558.11 313.03 534.16 316.47 524.28C319.92 514.4 333.44 465.03 335.57 456.11C337.7 447.19 344.56 418.71 344.56 418.71C341.34 418.9 331.29 418.05 322.72 413.63C314.15 409.22 309.84 404.63 304.71 398.24L218.98 506.47C218.2 546.53 235.67 551.23 235.67 551.23L294.37 566.97L294.38 566.99Z" fill="#45299A"/>
                                        <path d="M241.19 552.72C262.11 524.38 314.11 446.08 318.22 438.86C322.65 431.09 325.26 422.12 326.45 415.3C325.19 414.81 323.94 414.26 322.72 413.63C314.15 409.22 309.84 404.63 304.71 398.24L218.98 506.47C218.2 546.53 235.67 551.23 235.67 551.23L241.18 552.71L241.19 552.72Z" fill="#5D3DBF"/>
                                        <path d="M463.61 375.4C463.61 375.4 454.94 366.36 454.79 366.06C454.6 365.68 454.22 365.24 454.24 364.59L454.04 349.17C454.04 349.17 453.69 346.02 456.12 345.42L456.17 336.92C456.35 332.14 457.37 331.27 459.45 330.85C459.45 330.85 458.07 321.3 461.41 320.93C463.89 320.65 489.63 326.48 489.63 326.48L471.62 294.58C469.43 289.04 471.12 283.99 474.02 283.4C476.1 282.98 479.55 286.54 481.51 288.81C484.02 291.7 514.4 328.55 515.23 329.8C516.56 331.55 517.37 335.32 516.9 337.29C516.9 338.54 501.35 383.93 501.35 383.93L553.72 451.5L523.56 484.06L465.27 392.05C462.77 388.3 462.77 380.39 463.6 375.4H463.61Z" fill="#FFCEC9"/>
                                        <path d="M459.19 327.85L482.82 340.04L459.3 330.88C459.35 330.87 459.4 330.86 459.45 330.85C459.45 330.85 459.26 329.53 459.19 327.85Z" fill="#D77D79"/>
                                        <path d="M475.39 354.25L456.12 345.42L456.14 341.15L475.39 354.25Z" fill="#D77D79"/>
                                        <path d="M460.4 371.84C460.55 372.13 463.62 375.39 463.62 375.39L468.14 365.05L459.79 359.88C459.66 362.61 458.97 368.32 460.4 371.83V371.84Z" fill="#D77D79"/>
                                        <path d="M477.37 418.99C477.37 418.99 522.29 553.23 527.48 567.01L498.86 574.66L477.37 418.99Z" fill="#2C1A62"/>
                                        <path d="M527.47 566.99C518.85 558.11 508.82 534.16 505.38 524.28C501.93 514.4 488.41 465.03 486.28 456.11C484.15 447.19 477.29 418.71 477.29 418.71C480.51 418.9 490.56 418.05 499.13 413.63C507.7 409.22 512.01 404.63 517.14 398.24L602.87 506.47C603.65 546.53 586.18 551.23 586.18 551.23L527.48 566.97L527.47 566.99Z" fill="#45299A"/>
                                        <path d="M580.65 552.72C559.73 524.38 507.73 446.08 503.62 438.86C499.19 431.09 496.58 422.12 495.39 415.3C496.65 414.81 497.9 414.26 499.12 413.63C507.69 409.22 512 404.63 517.13 398.24L602.86 506.47C603.64 546.53 586.17 551.23 586.17 551.23L580.66 552.71L580.65 552.72Z" fill="#5D3DBF"/>
                                        </g>
                                        <mask id="mask1_582_5333" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="17" y="120" width="800" height="401">
                                        <path d="M617 120.65H217C106.543 120.65 17 210.193 17 320.65C17 431.107 106.543 520.65 217 520.65H617C727.457 520.65 817 431.107 817 320.65C817 210.193 727.457 120.65 617 120.65Z" fill="#E8A645"/>
                                        </mask>
                                        <g mask="url(#mask1_582_5333)">
                                        <path d="M647 385.67C653.4 388.66 664.5 402.74 666.2 405.73C667.9 408.72 682.42 445.85 684.55 458.22L654.25 459.5C649.98 449.26 646.57 419.38 644.86 408.29C643.15 397.2 643.15 389.51 643.58 386.53C644.01 383.55 646.57 384.4 646.99 385.68L647 385.67Z" fill="#4B9B7C"/>
                                        <path d="M648.28 459.5C638.46 452.25 632.92 442.43 636.76 431.76C640.6 421.09 644.01 418.96 652.12 413.41C660.23 407.86 667.91 401.46 669.62 390.79C671.33 380.12 672.18 373.29 671.33 368.17C670.48 363.05 673.46 364.33 676.02 365.18C678.58 366.03 687.6 373.11 689.68 378.41C693.52 388.23 693.95 411.7 692.67 418.1C691.39 424.5 690.11 450.53 676.03 458.64C661.95 466.75 648.29 459.49 648.29 459.49L648.28 459.5Z" fill="#7EAE7E"/>
                                        <path d="M718.27 389.94C709.31 389.51 694.37 396.34 690.1 402.32C685.83 408.3 683.7 419.82 677.3 424.94C670.9 430.06 660.66 433.9 656.82 442.01C652.98 450.12 655.54 457.37 660.23 459.93C664.92 462.49 691.81 460.78 691.81 460.78C698.21 455.66 701.63 448.4 702.05 440.3C702.48 432.19 701.62 426.22 707.6 418.54C713.58 410.86 719.55 403.18 721.26 398.06C722.97 392.94 720.83 390.38 718.27 389.95V389.94Z" fill="#438E6F"/>
                                        <path d="M704.45 456.62H633.01V465.43H704.45V456.62Z" fill="#284693"/>
                                        <path d="M668.729 463.77L638.859 464.29L644.949 519.26H668.729H692.519L698.609 464.29L668.729 463.77Z" fill="#284693"/>
                                        </g>
                                        <rect y="33.8572" width="194.3" height="122.514" rx="42.1143" fill="white"/>
                                        <path d="M271.63 216.239L192.142 125.201L154.582 146.622L271.63 216.239Z" fill="white"/>
                                        <path d="M62.7909 80.9765H44.4098V99.3576H62.7909V80.9765ZM48.8263 115.425H75.0309V111.135H54.1682V103.017H48.8263V115.425ZM49.7096 95.1514V85.2668H57.4911V95.1514H49.7096ZM68.2589 105.751H73.6429V93.2586H78.8165V88.8421H73.6429V77.8218H68.2589V105.751ZM115.264 92.3332H109.838V77.8218H104.454V116.014H109.838V96.7077H115.264V92.3332ZM80.8573 106.676H84.1802C89.8165 106.676 96.0206 106.34 102.435 105.036L101.888 100.577C96.3571 101.692 91.0994 102.155 86.1992 102.239V81.4812H80.8573V106.676ZM130.47 86.4866V80.7662H125.044V86.4866C125.002 93.6792 122.016 101.061 115.454 104.026L118.819 108.401C123.194 106.256 126.138 102.134 127.778 97.1072C129.377 101.839 132.237 105.667 136.485 107.728L139.766 103.438C133.394 100.683 130.491 93.6792 130.47 86.4866ZM135.476 94.1839H143.005V116.182H148.304V77.8218H143.005V89.8516H135.476V94.1839Z" fill="#6C4FC9"/>
                                        <rect x="591.086" y="75.0142" width="267.043" height="122.514" rx="42.1143" fill="white"/>
                                        <path d="M567.614 254.135C561.953 255.786 610.331 206.682 635.228 181.924H695.766C655.407 205.307 573.274 252.485 567.614 254.135Z" fill="white"/>
                                        <path d="M664.864 117.782H659.48V145.963H664.864V132.966H670.038V128.55H664.864V117.782ZM635.631 139.864H654.181V120.474H648.797V126.026H640.973V120.474H635.631V139.864ZM640.048 155.343H666.252V151.053H645.39V143.061H640.048V155.343ZM640.973 135.658V130.148H648.797V135.658H640.973ZM701.228 117.74H695.802V138.518H701.228V130.443H706.401V126.026H701.228V117.74ZM670.691 134.649L672.626 138.981C684.298 136.352 690.607 129.938 690.586 119.801H672.626V124.007H684.655C683.982 128.718 679.629 132.756 670.691 134.649ZM676.075 155.596H701.228V139.991H695.844V143.734H681.501V139.991H676.075V155.596ZM681.501 151.263V147.856H695.844V151.263H681.501ZM716.602 140.706H711.302V155.596H737.717V140.706H732.376V143.944H716.602V140.706ZM707.222 138.771H742.134V134.438H707.222V138.771ZM708.947 128.55L710.882 132.756C717.401 131.915 722.197 129.181 724.678 125.479C727.139 129.181 731.934 131.915 738.517 132.756L740.409 128.55C731.85 127.582 727.454 123.25 727.454 119.422V118.118H721.944V119.422C721.902 123.355 717.486 127.519 708.947 128.55ZM716.602 151.389V148.024H732.376V151.389H716.602ZM775.763 117.74H770.421V156.016H775.763V117.74ZM745.647 146.552H749.012C754.922 146.573 761.273 146.153 767.982 144.744L767.309 140.327C761.673 141.421 756.12 141.904 750.947 141.989V121.399H745.647V146.552ZM810.15 117.74H804.766V156.1H810.15V136.331H815.786V131.957H810.15V117.74ZM781.464 146.805H784.702C791.453 146.826 796.669 146.678 802.621 145.669L802.032 141.168C796.942 142.052 792.358 142.346 786.805 142.388V125.605H799.424V121.273H781.464V146.805Z" fill="#6C4FC9"/>
                                    </svg>
                                </div>
                                <div className='ani2' >
                                    <svg width="859" height="521" viewBox="0 0 859 521" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M617 120.65H217C106.543 120.65 17 210.193 17 320.65C17 431.107 106.543 520.65 217 520.65H617C727.457 520.65 817 431.107 817 320.65C817 210.193 727.457 120.65 617 120.65Z" fill="#E8A645"/>
                                        <mask id="mask0_646_1906" style={{maskType:"alpha"}}maskUnits="userSpaceOnUse" x="17" y="0" width="800" height="521">
                                        <path d="M816.53 306.97C816.83 302.17 817 297.33 817 292.45V228.17C817 102.16 714.84 0 588.83 0H245.17C119.16 0 17 102.16 17 228.17V292.45C17 297.33 17.17 302.17 17.47 306.97C17.17 311.49 17 316.03 17 320.62C17 431.08 106.54 520.62 217 520.62H617C626.46 520.62 635.77 519.95 644.89 518.68L644.95 519.23H692.52L694.07 505.22C766.26 475.04 817 403.76 817 320.62C817 316.03 816.83 311.48 816.53 306.97V306.97Z" fill="#C4C4C4"/>
                                        </mask>
                                        <g mask="url(#mask0_646_1906)">
                                        <path d="M355.441 145.15C355.441 145.15 348.761 108.13 360.621 85.2401C375.521 56.4601 418.511 43.5501 454.351 71.2201C479.691 90.7801 465.681 148.82 465.681 148.82L390.911 117.03L355.451 145.15H355.441Z" fill="#23130F"/>
                                        <path d="M438.59 252.63L411.88 278.02L385.17 252.63L389.87 195.09H433.9L438.59 252.63Z" fill="#FFCEC9"/>
                                        <path d="M364.821 138.64C364.821 138.64 358.501 134.56 353.611 141.07C348.721 147.59 351.971 172.04 364.201 172.04C376.431 172.04 364.821 138.64 364.821 138.64Z" fill="#FFCEC9"/>
                                        <path d="M459.33 138.64C459.33 138.64 465.65 134.56 470.54 141.07C475.43 147.59 472.17 172.04 459.95 172.04C447.73 172.04 459.33 138.64 459.33 138.64Z" fill="#FFCEC9"/>
                                        <path d="M461.37 131.85C461.37 104.42 439.22 86.4701 411.88 86.4701C384.54 86.4701 362.38 104.42 362.38 131.85C362.38 131.85 360.23 166.84 360.23 168.54C360.23 194.6 392.14 227.66 411.88 227.66C434.67 227.66 463.52 188.28 463.52 168.54C463.52 166.84 461.36 131.85 461.36 131.85H461.37Z" fill="#FFCEC9"/>
                                        <path d="M450.3 34.0001C450.96 53.7501 436.48 66.5701 414.82 67.3001C393.15 68.0201 382.85 55.4501 382.2 35.7001C381.54 15.9501 392.48 0.750072 414.13 0.0300721C435.79 -0.689928 449.64 14.2601 450.3 34.0101V34.0001Z" fill="#23130F"/>
                                        <path d="M422.081 133.06C422.081 133.06 442.461 131.03 446.391 131.41C452.941 132.04 454.341 136.29 455.361 138.6C455.711 139.39 428.751 142.03 424.831 140.61C420.911 139.19 422.081 133.06 422.081 133.06V133.06Z" fill="#23130F"/>
                                        <path d="M401.75 133.06C401.75 133.06 381.37 131.03 377.44 131.41C370.89 132.04 369.48 136.29 368.47 138.6C368.12 139.39 395.08 142.03 399 140.61C402.92 139.19 401.75 133.06 401.75 133.06V133.06Z" fill="#23130F"/>
                                        <path d="M386.8 177.44C376.28 177.44 367.73 168.89 367.73 158.37C367.73 147.85 376.29 139.3 386.8 139.3C397.31 139.3 405.87 147.85 405.87 158.37C405.87 168.89 397.31 177.44 386.8 177.44ZM386.8 141.18C377.32 141.18 369.61 148.89 369.61 158.37C369.61 167.85 377.32 175.55 386.8 175.55C396.28 175.55 403.99 167.84 403.99 158.37C403.99 148.9 396.28 141.18 386.8 141.18Z" fill="#45299A"/>
                                        <path d="M437.15 177.44C426.63 177.44 418.08 168.89 418.08 158.37C418.08 147.85 426.63 139.3 437.15 139.3C447.67 139.3 456.22 147.85 456.22 158.37C456.22 168.89 447.67 177.44 437.15 177.44ZM437.15 141.18C427.67 141.18 419.96 148.89 419.96 158.37C419.96 167.85 427.67 175.55 437.15 175.55C446.63 175.55 454.34 167.84 454.34 158.37C454.34 148.9 446.63 141.18 437.15 141.18Z" fill="#45299A"/>
                                        <path d="M419.03 157.43H404.93V159.31H419.03V157.43Z" fill="#45299A"/>
                                        <path d="M403.371 189.04H421.991L412.681 192.62L403.371 189.04Z" fill="#D77D79"/>
                                        <path d="M411.582 280.15C437.382 280.15 449.982 254.74 443.932 252.93C426.842 247.81 414.192 244.71 414.192 244.71C414.192 244.71 399.732 247.99 380.202 253.99C373.502 256.05 386.502 280.15 411.582 280.15Z" fill="#FFCEC9"/>
                                        <path d="M249.55 561.42C249.55 561.42 200.28 570.32 190.13 519.21C186.08 498.81 196.93 464.51 213.26 429.59C219.75 415.72 243.11 371.59 250.61 356.46C261.38 334.76 275.72 309.39 292.77 294.85C308.91 281.09 356.4 262.49 380.19 254C383.07 265.44 396.85 271.35 410.9 271.6H414.3C428.35 271.35 442.13 265.45 445.01 254C468.8 262.48 516.3 281.08 532.43 294.85C549.48 309.39 563.82 334.76 574.59 356.46C582.1 371.59 605.45 415.72 611.94 429.59C628.27 464.5 639.12 498.81 635.07 519.21C624.92 570.33 575.65 561.42 575.65 561.42H249.55Z" fill="#45299A"/>
                                        <path d="M502.501 407.15H321.951V387.37L496.351 387.53L502.501 407.15Z" fill="white"/>
                                        <path d="M515.671 449.86L321.951 450.02V430.24L509.471 430.07L515.671 449.86Z" fill="white"/>
                                        <path d="M372.77 419.98L342.48 416.17L270.36 466.28L251.15 443.99L323.64 378.56C324.57 373.92 326.4 368.45 330.42 365.44C335.82 361.39 359.02 346.71 359.02 346.71C362.85 344.44 364.2 347.68 364.32 349.5C364.51 352.37 362.28 356 358.84 359.65C356.04 362.61 351.5 369.49 351.5 369.49C351.5 369.49 361.97 372.85 367.24 373.5C372.72 374.17 399.48 357.17 399.48 357.17C402.69 355.88 405.78 355.13 407.1 357.12C408.42 359.11 407.45 361.55 406.59 364.14C410.78 362.78 413.61 366.93 413.13 369.51C412.82 371.19 411.84 372.95 411.3 374.32C411.3 374.32 413.34 374.25 414.83 375.78C416.94 377.94 416.21 380.92 414.74 383.21C413.35 385.37 409.77 388.97 409.77 388.97C409.77 388.97 412.35 391.86 412.77 394.42C413.67 397.26 406.88 401.67 404.35 404.61C402.17 406.77 386.34 418 382.58 419.42C378.72 420.99 376.85 420.93 372.77 419.98V419.98Z" fill="#FFCEC9"/>
                                        <path d="M406.59 364.13C406.68 363.87 406.77 363.6 406.86 363.34L397.05 370.6L377.31 384.06L366.4 383.14L378.83 385.88C378.83 385.88 399.41 370.81 408.9 363.91C408.19 363.8 407.42 363.85 406.59 364.12V364.13Z" fill="#D77D79"/>
                                        <path d="M413.06 374.63C412.1 374.28 411.31 374.31 411.31 374.31C411.41 374.06 411.53 373.79 411.65 373.52L381.41 397.43L370.33 396.24L383.8 399.2L413.07 374.63H413.06Z" fill="#D77D79"/>
                                        <path d="M410.651 390.07C410.161 389.4 409.771 388.97 409.771 388.97C409.771 388.97 410.251 388.49 410.911 387.78L384.371 408.44L373.471 407.72L386.211 410.3L410.641 390.07H410.651Z" fill="#D77D79"/>
                                        <path d="M332.141 426.37C332.141 426.37 261.611 549.11 253.811 561.59L280.351 574.74L332.141 426.37V426.37Z" fill="#2C1A62"/>
                                        <path d="M253.831 561.58C264.031 554.57 278.591 533.08 283.921 524.07C289.251 515.06 312.251 469.34 316.101 461.01C319.951 452.69 332.291 426.12 332.291 426.12C329.101 425.67 319.411 422.85 311.881 416.84C304.351 410.82 301.031 405.48 297.261 398.2L191.861 487.39C183.201 526.51 199.391 534.57 199.391 534.57L253.831 561.59V561.58Z" fill="#45299A"/>
                                        <path d="M204.501 537.1C230.601 513.44 297.031 446.94 302.481 440.68C308.351 433.93 312.681 425.66 315.201 419.21C314.071 418.48 312.951 417.7 311.881 416.84C304.351 410.82 301.031 405.48 297.261 398.2L191.861 487.39C183.201 526.51 199.391 534.57 199.391 534.57L204.501 537.11V537.1Z" fill="#5D3DBF"/>
                                        <path d="M456.859 481.56L486.879 476L561.779 521.84L579.669 498.48L503.509 437.36C502.309 432.78 500.169 427.43 495.979 424.65C490.359 420.92 466.339 407.61 466.339 407.61C462.389 405.56 461.219 408.88 461.209 410.7C461.189 413.58 463.619 417.07 467.269 420.51C470.229 423.3 475.169 429.91 475.169 429.91C475.169 429.91 464.909 433.87 459.689 434.82C454.259 435.81 426.549 420.39 426.549 420.39C423.269 419.29 420.139 418.72 418.939 420.78C417.739 422.84 418.849 425.23 419.859 427.76C415.589 426.64 413.019 430.95 413.639 433.5C414.049 435.16 415.119 436.86 415.739 438.19C415.739 438.19 413.699 438.24 412.299 439.85C410.319 442.13 411.219 445.06 412.819 447.27C414.329 449.35 418.119 452.74 418.119 452.74C418.119 452.74 415.709 455.78 415.439 458.36C414.699 461.25 421.739 465.26 424.439 468.05C426.739 470.08 443.189 480.38 447.029 481.57C450.969 482.92 452.839 482.75 456.859 481.56V481.56Z" fill="#FFCEC9"/>
                                        <path d="M419.859 427.77C419.759 427.51 419.649 427.25 419.549 427L429.769 433.68L450.249 445.97L461.089 444.42L448.839 447.88C448.839 447.88 427.419 434.03 417.549 427.69C418.249 427.54 419.019 427.55 419.859 427.77Z" fill="#D77D79"/>
                                        <path d="M414.01 438.63C414.95 438.22 415.74 438.2 415.74 438.2C415.63 437.96 415.49 437.69 415.35 437.43L446.93 459.55L457.93 457.72L444.66 461.46L414.01 438.63V438.63Z" fill="#D77D79"/>
                                        <path d="M417.31 453.9C417.76 453.2 418.12 452.75 418.12 452.75C418.12 452.75 417.62 452.3 416.91 451.63L444.6 470.72L455.44 469.37L442.87 472.68L417.31 453.9Z" fill="#D77D79"/>
                                        <path d="M501.719 488.36C501.719 488.36 629.209 549.87 642.699 555.78L628.209 581.61L501.719 488.36Z" fill="#2C1A62"/>
                                        <path d="M642.669 555.77C630.289 555.74 606.139 546.22 596.659 541.79C587.179 537.36 542.379 512.59 534.509 507.9C526.629 503.2 501.449 488.24 501.449 488.24C503.829 486.07 510.229 478.27 513.059 469.06C515.879 459.84 515.599 453.56 514.609 445.42L623.439 456.74C633.539 478.81 641.019 498.26 643.269 510.24C645.519 522.22 642.669 555.79 642.669 555.79V555.77Z" fill="#45299A"/>
                                        <path d="M512.42 470.97C512.42 470.97 512.39 471.06 512.37 471.1C512.15 471.7 511.91 472.3 511.66 472.89C517.38 476.79 525.62 481.18 534.28 483.43C540.64 485.09 599.93 496.35 641.64 503.35C638.39 491.56 631.94 475.35 623.72 457.34C623.5 457.14 623.29 456.94 623.07 456.73L514.62 445.41C515.61 453.55 515.89 459.83 513.07 469.05C512.87 469.7 512.65 470.33 512.42 470.96V470.97Z" fill="#5D3DBF"/>
                                        </g>
                                        <mask id="mask1_646_1906" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="17" y="120" width="800" height="401">
                                        <path d="M617 120.649H217C106.543 120.649 17 210.192 17 320.649C17 431.106 106.543 520.649 217 520.649H617C727.457 520.649 817 431.106 817 320.649C817 210.192 727.457 120.649 617 120.649Z" fill="#E8A645"/>
                                        </mask>
                                        <g mask="url(#mask1_646_1906)">
                                        <path d="M647 385.67C653.4 388.66 664.5 402.74 666.2 405.73C667.91 408.72 682.42 445.85 684.55 458.22L654.25 459.5C649.98 449.26 646.57 419.38 644.86 408.29C643.15 397.2 643.15 389.51 643.58 386.53C644.01 383.55 646.57 384.4 646.99 385.68L647 385.67Z" fill="#4B9B7C"/>
                                        <path d="M648.28 459.5C638.46 452.25 632.92 442.43 636.76 431.76C640.6 421.09 644.01 418.96 652.12 413.41C660.23 407.86 667.91 401.46 669.62 390.79C671.33 380.12 672.18 373.29 671.33 368.17C670.48 363.05 673.46 364.33 676.02 365.18C678.58 366.03 687.6 373.11 689.68 378.41C693.52 388.23 693.95 411.7 692.67 418.1C691.39 424.5 690.11 450.53 676.03 458.64C661.95 466.75 648.29 459.49 648.29 459.49L648.28 459.5Z" fill="#7EAE7E"/>
                                        <path d="M718.27 389.94C709.31 389.51 694.37 396.34 690.1 402.32C685.83 408.29 683.7 419.82 677.3 424.94C670.9 430.06 660.66 433.9 656.82 442.01C652.98 450.12 655.54 457.37 660.23 459.93C664.92 462.49 691.81 460.78 691.81 460.78C698.21 455.66 701.63 448.4 702.05 440.3C702.48 432.19 701.62 426.22 707.6 418.54C713.57 410.86 719.55 403.18 721.26 398.06C722.97 392.94 720.83 390.38 718.27 389.95V389.94Z" fill="#438E6F"/>
                                        <path d="M704.45 456.62H633.01V465.43H704.45V456.62Z" fill="#284693"/>
                                        <path d="M668.729 463.77L638.859 464.29L644.949 519.26H668.729H692.519L698.609 464.29L668.729 463.77Z" fill="#284693"/>
                                        </g>
                                        <rect y="33.8572" width="194.3" height="122.514" rx="42.1143" fill="white"/>
                                        <path d="M271.63 216.239L192.142 125.201L154.582 146.622L271.63 216.239Z" fill="white"/>
                                        <path d="M62.7899 80.9765H44.4088V99.3576H62.7899V80.9765ZM48.8253 115.425H75.0299V111.135H54.1672V103.017H48.8253V115.425ZM49.7086 95.1514V85.2668H57.4901V95.1514H49.7086ZM68.2579 105.751H73.6419V93.2586H78.8155V88.8421H73.6419V77.8218H68.2579V105.751ZM115.263 92.3332H109.837V77.8218H104.453V116.014H109.837V96.7077H115.263V92.3332ZM80.8563 106.676H84.1792C89.8155 106.676 96.0197 106.34 102.434 105.036L101.887 100.577C96.3562 101.692 91.0984 102.155 86.1982 102.239V81.4812H80.8563V106.676ZM130.469 86.4866V80.7662H125.043V86.4866C125.001 93.6792 122.015 101.061 115.453 104.026L118.818 108.401C123.193 106.256 126.137 102.134 127.777 97.1072C129.376 101.839 132.236 105.667 136.484 107.728L139.765 103.438C133.393 100.683 130.49 93.6792 130.469 86.4866ZM135.475 94.1839H143.004V116.182H148.304V77.8218H143.004V89.8516H135.475V94.1839Z" fill="#6C4FC9"/>
                                        <rect x="591.086" y="75.0143" width="267.043" height="122.514" rx="42.1143" fill="white"/>
                                        <path d="M567.614 254.136C561.953 255.786 610.331 206.682 635.228 181.924H695.766C655.407 205.307 573.274 252.485 567.614 254.136Z" fill="white"/>
                                        <path d="M664.864 117.782H659.48V145.963H664.864V132.966H670.038V128.55H664.864V117.782ZM635.631 139.864H654.181V120.474H648.797V126.026H640.973V120.474H635.631V139.864ZM640.048 155.343H666.252V151.053H645.39V143.061H640.048V155.343ZM640.973 135.658V130.148H648.797V135.658H640.973ZM701.228 117.74H695.802V138.518H701.228V130.443H706.401V126.026H701.228V117.74ZM670.691 134.649L672.626 138.981C684.298 136.352 690.607 129.938 690.586 119.801H672.626V124.007H684.655C683.982 128.718 679.629 132.756 670.691 134.649ZM676.075 155.596H701.228V139.991H695.844V143.734H681.501V139.991H676.075V155.596ZM681.501 151.263V147.856H695.844V151.263H681.501ZM716.602 140.706H711.302V155.596H737.717V140.706H732.376V143.944H716.602V140.706ZM707.222 138.771H742.134V134.438H707.222V138.771ZM708.947 128.55L710.882 132.756C717.401 131.915 722.197 129.181 724.678 125.479C727.139 129.181 731.934 131.915 738.517 132.756L740.409 128.55C731.85 127.582 727.454 123.25 727.454 119.422V118.118H721.944V119.422C721.902 123.355 717.486 127.519 708.947 128.55ZM716.602 151.389V148.024H732.376V151.389H716.602ZM775.763 117.74H770.421V156.016H775.763V117.74ZM745.647 146.552H749.012C754.922 146.573 761.273 146.153 767.982 144.744L767.309 140.327C761.673 141.421 756.12 141.904 750.947 141.989V121.399H745.647V146.552ZM810.15 117.74H804.766V156.1H810.15V136.331H815.786V131.957H810.15V117.74ZM781.464 146.805H784.702C791.453 146.826 796.669 146.678 802.621 145.669L802.032 141.168C796.942 142.052 792.358 142.346 786.805 142.388V125.605H799.424V121.273H781.464V146.805Z" fill="#6C4FC9"/>
                                    </svg>
                                </div>
                                <div className="copyDiv">
                                    <p className="main-title">AI 기술로 언어의 장벽을 넘겠습니다</p>
                                    <p className="sub-title">수어 실시간 통역 서비스</p>
                                </div>
                            </div>
                            <div className="section2" id="txt3section">
                                <div className="section2_desc">
                                    <p className="disc1">수어를 알고 계신가요?<br/>수어는 30만명이 넘는 한국인이 사용하는 제2의 국어입니다.<br/>하지만 수어를 인식하는 서비스는 찾아보기 힘들죠.</p>
                                    <p className='disc2' style={{
                                        display:"none"
                                    }}>우리는 장애가 더이상 차별이 아닌 세상을 꿈꿉니다.<br/>포스팟은 정확도 높은 인공지능 수어 인식 기술로<br/>수어 사용자에게 더 넓은 세상을 경험하게 할 것입니다.</p>
                                </div>

                                <div className="imgDiv imgDiv3" >
                                    <div className="box2" >
                                    <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_582_4058" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                        <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                        </mask>
                                        <g mask="url(#mask0_582_4058)">
                                        <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                        <path d="M99.309 112.536L99.2914 112.386L99.309 112.536Z" fill="#FAB4AD"/>
                                        <path d="M116.568 74.5063C116.568 74.5063 115.809 80.9651 113.921 93.821C112.041 106.686 111.521 148.65 109.112 157.315C109.112 157.315 107.488 162.292 101.674 161.939C101.674 161.939 96.459 162.353 97.7119 151.827C98.9649 141.292 97.4825 119.083 96.8913 112.465C96.8913 112.465 90.2207 116.489 86.9031 115.403C83.5943 114.318 81.4237 106.483 81.4237 106.483C81.4237 106.483 76.2619 109.306 72.9531 108.23C69.6355 107.145 67.0502 99.9445 67.0502 99.9445C67.0502 99.9445 62.3119 102.142 59.0031 101.056C55.6943 99.9622 52.659 90.6534 52.0943 80.9475C51.5296 71.2328 61.7384 46.2798 66.3355 33.7328C66.3355 33.7328 94.8796 25.421 98.656 25.5269C102.433 25.6328 116.418 38.321 117.997 39.5386C119.568 40.7563 128.674 51.1416 133.35 60.9975C138.035 70.8534 146.912 74.321 154.527 76.9504C154.527 76.9504 156.918 79.9945 154.138 83.1004C151.359 86.2063 140.55 87.3357 131.171 79.3328C131.171 79.3328 122.462 71.4004 120.088 71.1181C117.715 70.8357 116.585 74.4886 116.585 74.4886L116.568 74.5063Z" fill="#FFCEC9"/>
                                        <path d="M113.797 40.6855C113.797 40.6855 96.2737 7.93258 95.806 -5.55859L54.3002 4.41199C54.3002 4.41199 65.5237 27.4326 66.0796 35.7708C66.6355 44.1091 98.3031 51.4944 98.3031 51.4944C98.3031 51.4944 113.903 48.4502 113.797 40.6855Z" fill="#FFCEC9"/>
                                        <path d="M96.8914 112.456L95.8325 100.491L94.0149 114.106C94.0149 114.106 96.8649 112.262 96.8914 112.456Z" fill="#D77D79"/>
                                        <path d="M81.4148 106.483L79.8531 94.5093L78.2207 107.753C78.2207 107.753 81.1589 106.483 81.4148 106.483Z" fill="#D77D79"/>
                                        <path d="M67.0413 99.9443L64.6148 88.7031L63.1589 101.224C63.1589 101.224 66.1854 100.483 67.0325 99.9443H67.0413Z" fill="#D77D79"/>
                                        </g>
                                    </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5415" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5415)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M73.6056 160.138L65.0732 182.338H99.1938L111.326 161.576L73.6056 160.138Z" fill="#FFCEC9"/>
                                            <path d="M98.8502 143.691L89.0296 165.035L73.1384 165.512C73.1384 165.512 60.9796 133.88 59.109 122.188C57.2384 110.497 57.7061 92.2678 55.8355 83.8501C53.9649 75.4325 46.9502 52.059 46.9502 52.059C46.9502 52.059 52.0943 46.4472 56.3031 50.1884C60.512 53.9295 67.5267 82.4472 67.5267 82.4472C67.5267 82.4472 68.9296 87.5913 70.3325 88.059C71.7355 88.5266 72.6708 82.9237 71.2678 72.6354C69.8649 62.3472 67.5267 34.7648 67.059 32.4354C66.5914 30.0972 71.7355 28.2266 74.5414 30.0972C77.3473 31.9678 78.2825 36.1766 79.6855 49.2619C81.0884 62.356 83.4267 80.5766 83.4267 80.5766C83.4267 80.5766 85.7649 82.2972 86.7002 76.6854C87.6355 71.0825 91.8443 27.759 92.312 22.6148C92.312 22.6148 95.5855 18.406 98.3914 20.2766C101.197 22.1472 105.406 23.859 104.003 36.4854C102.6 49.1119 99.7943 77.1531 99.7943 77.1531C99.7943 77.1531 106.341 65.4707 108.68 64.0678C111.009 62.6648 123.168 73.8796 126.909 77.6296C126.909 77.6296 126.909 85.5796 128.78 89.3207C130.65 93.0619 124.103 95.5501 120.83 94.6237C117.556 93.6884 113.815 83.4001 113.815 83.4001L112.88 82.7825L106.333 93.5295C106.333 93.5295 107.735 108.027 110.074 113.171C112.412 118.315 117.556 120.644 117.556 120.644L99.7943 142.156L98.859 143.709L98.8502 143.691Z" fill="#FFCEC9"/>
                                            <path d="M140.93 107.074C140.93 107.074 132.98 97.4118 129.238 92.7353C125.497 88.0589 120.821 93.9795 121.756 99.5912C122.691 105.203 127.835 110.815 127.835 110.815C127.835 110.815 120.821 119.232 118.483 120.168C116.144 121.103 107.727 111.282 107.727 111.282L96.0443 117.979L82.9502 160.527L89.4973 166.138H108.194C108.194 166.138 122.868 143.877 127.827 138.397C133.906 131.7 145.588 113.153 140.921 107.074H140.93Z" fill="#FFCEC9"/>
                                            <path d="M123.971 101.268C123.045 99.0531 122.471 96.8384 122.25 94.8002C122.03 92.7619 122.25 94.8002 122.25 94.8002C122.25 94.8002 122.762 93.7678 122.25 94.8002C121.739 95.8325 121.615 97.2707 121.765 99.5913C122.7 105.203 127.845 110.815 127.845 110.815C127.845 110.815 120.83 119.233 118.492 120.168C119.859 119.885 119.709 121.183 122.109 119.338C124.509 117.494 130.783 109.924 130.783 109.924C128.453 108.433 124.898 103.483 123.971 101.268ZM124.439 93.8207C122.4 93.3796 117.733 87.5472 117.15 86.3825C116.568 85.2178 114.406 82.9149 114.406 82.9149L112.88 82.7737L113.815 83.3913C113.815 83.3913 117.556 93.6796 120.83 94.6149C121.253 94.7384 121.739 94.7913 122.25 94.8002L124.439 93.8207Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5425" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5425)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M67.8968 68.391C67.8968 68.391 74.9291 68.6028 88.985 69.4057C103.05 70.2086 148.359 66.7763 157.941 68.5498C157.941 68.5498 163.464 69.8292 163.641 76.1381C163.641 76.1381 164.585 81.7234 153.097 81.3704C141.617 81.0175 117.794 84.7322 110.709 86.0116C110.709 86.0116 115.694 92.8322 114.838 96.5116C113.982 100.191 105.741 103.27 105.741 103.27C105.741 103.27 109.279 108.565 108.423 112.244C107.567 115.923 100.05 119.4 100.05 119.4C100.05 119.4 102.864 124.306 102.017 127.985C101.161 131.665 91.4027 135.812 80.9733 137.347C70.5527 138.882 42.6527 130.253 28.685 126.485C28.685 126.485 17.0027 96.4763 16.7556 92.3998C16.5086 88.3145 28.8615 72.0175 30.0262 70.1998C31.1909 68.3822 41.5321 57.5822 51.7144 51.5998C61.9056 45.6086 64.7997 35.691 66.9086 27.2292C66.9086 27.2292 69.9615 24.3616 73.588 27.0616C77.2144 29.7616 79.4556 41.3204 71.7085 52.1998C71.7085 52.1998 63.988 62.3469 63.9086 64.9322C63.8291 67.5263 67.8791 68.391 67.8791 68.391H67.8968Z" fill="#FFCEC9"/>
                                            <path d="M31.6674 74.6028C31.6674 74.6028 6.56448 83.5322 -7.94141 85.3145L-2.49729 134.673C-2.49729 134.673 21.9792 127.95 30.9174 126.547C39.8557 125.162 44.8057 90.291 44.8057 90.291C44.8057 90.291 40.0409 73.7469 31.6674 74.594V74.6028Z" fill="#FFCEC9"/>
                                            <path d="M110.709 86.0117L101.78 87.2205L94.6943 88.2088C96.9002 90.4147 107.921 91.8794 113.48 90.9352C113.48 90.9352 112.244 88.1294 110.709 86.0117Z" fill="#D77D79"/>
                                            <path d="M105.741 103.271L96.4235 104.832L93.0176 105.3C93.0176 105.3 95.7705 107.859 97.1029 108.335C99.7588 109.571 104.506 109.094 108.212 108.053C108.212 108.053 106.606 104.276 105.75 103.271H105.741Z" fill="#D77D79"/>
                                            <path d="M100.059 119.4L93.3263 121.412L89.7881 122.576C91.4028 124.473 93.3969 125.329 94.8175 125.329C96.2381 125.329 99.4675 125.047 101.559 123.529C101.559 123.529 100.826 120.212 100.05 119.4H100.059Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5436" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5436)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M111.229 153.159C111.229 153.159 104.365 162.918 100.138 179.912L54.7764 178.668C54.7764 178.668 64.6322 168.459 65.7528 156.256C66.8822 144.053 72.2381 135.909 72.2381 135.909L111.238 153.159H111.229Z" fill="#FFCEC9"/>
                                            <path d="M110.25 159.706C110.25 159.706 97.8175 166.994 94.2528 167.691C90.6793 168.379 65.2852 158.603 65.2852 158.603C57.6969 147.741 56.5146 118.5 57.5205 110.629C58.5263 102.758 58.1293 74.0644 58.2881 58.8085C58.4381 43.5526 61.6146 42.8467 63.741 42.1232C65.8675 41.3909 69.2557 42.5379 69.2557 42.5379L69.3881 37.5438C69.2999 30.9614 72.8116 27.8997 76.7557 27.7409C80.7175 27.5909 82.1822 31.3144 82.1822 31.3144C82.641 24.2203 85.5793 22.7203 89.541 22.2967C93.5028 21.882 95.5234 24.8291 95.991 26.9467C96.4587 29.0644 97.0675 35.9203 97.0675 35.9203C97.4734 30.4056 102.485 30.0173 102.485 30.0173C108.009 30.1673 109.306 35.8585 109.306 35.8585C110.4 39.0438 111.891 56.4526 112.676 71.082C113.462 85.7114 114.282 99.1497 114.282 99.1497L123.926 121.65L110.25 159.697" fill="#FFCEC9"/>
                                            <path d="M131.197 103.729L101.7 87.8997C101.7 87.8997 97.4734 95.532 101.726 100.191C105.97 104.858 115.394 110.206 115.394 110.206L116.02 119.294C116.02 119.294 97.9499 128.85 92.8587 141.864C89.6028 147.697 86.2764 154.958 85.6675 157.685C85.0587 160.411 79.0058 176.153 76.2793 178.879L77.6558 178.95L77.4793 179.285L100.147 179.903C102.009 172.411 104.382 166.332 106.456 161.876C108.662 160.641 110.259 159.706 110.259 159.706C110.259 159.706 130.218 139.094 131.444 134.444C132.653 129.794 131.197 103.72 131.197 103.72V103.729Z" fill="#FFCEC9"/>
                                            <path d="M120.07 122.682L119.364 107.92C119.364 107.92 113.744 104.761 107.064 100.544C102.635 97.7467 100.826 93.3085 101.7 87.8997C101.7 87.8997 97.4732 95.532 101.726 100.191C105.97 104.858 115.394 110.206 115.394 110.206L116.02 119.294L120.079 122.682H120.07Z" fill="#D77D79"/>
                                            <path d="M97.0676 35.9292L96.2646 83.0821L99.3352 82.9674C99.1323 75.8557 97.0676 35.9292 97.0676 35.9292Z" fill="#D77D79"/>
                                            <path d="M82.1908 31.3145L81.6084 83.8409L84.7231 82.9586C84.6172 72.2909 82.1908 31.3145 82.1908 31.3145Z" fill="#D77D79"/>
                                            <path d="M69.2555 42.5378L68.0996 85.7467L71.6555 84.5731C71.532 74.6996 69.2555 42.5378 69.2555 42.5378Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5449" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5449)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M14.5331 77.6032C14.5331 77.6032 36.9448 62.4973 44.2595 57.6267C51.5742 52.7561 69.6007 52.7561 74.9566 55.1914C80.3125 57.6267 90.0625 67.3679 90.0625 67.3679C94.4566 67.862 142.201 68.7091 154.386 69.1944C166.562 69.6885 166.077 76.7473 166.077 76.7473C166.077 76.7473 167.18 83.6914 154.757 82.5973C142.324 81.5032 93.3536 84.3003 90.7948 85.0326C90.7948 85.0326 88.9684 88.3238 92.6213 89.0561C96.2742 89.7885 155.851 99.0444 160.96 100.138C166.068 101.233 166.439 105.98 165.707 108.177C164.974 110.374 162.786 113.665 151.448 112.2C140.127 110.744 107.604 108.062 104.674 108.062C104.674 108.062 106.501 111.715 105.398 113.912C104.312 116.1 98.0919 120.856 98.0919 120.856C98.0919 120.856 99.9184 125.974 98.0919 129.627C96.2654 133.28 82.3772 139.13 74.3389 138.759C66.3007 138.397 24.9978 125.603 19.5184 125.233C19.5184 125.233 6.73306 102.574 5.26835 97.8267C3.80365 93.0797 14.5242 77.6032 14.5242 77.6032H14.5331Z" fill="#FFCEC9"/>
                                            <path d="M22.0945 75.5298L-12.3525 85.7563L-6.13195 135.459L19.5357 125.224L35.9828 112.809L22.0945 75.5298Z" fill="#FFCEC9"/>
                                            <path d="M90.0619 67.3679C90.0619 67.3679 77.6824 66.6443 66.4766 66.6443C72.6972 65.6826 77.1001 64.8179 80.8324 64.959C84.5648 65.1002 86.3648 65.7884 90.0619 67.3767V67.3679Z" fill="#D77D79"/>
                                            <path d="M104.683 108.053L89.2242 107.056C89.2242 107.056 88.0684 110.091 88.3595 111.971C90.8213 112.112 94.8625 111.389 96.1684 111.247C97.4742 111.106 103.218 109.765 104.683 108.044V108.053Z" fill="#D77D79"/>
                                            <path d="M98.1091 120.847C98.1091 120.847 88.8267 123.927 85.9061 124.412C85.9061 124.412 85.1826 126.724 85.1826 127.88C88.3679 127.88 95.1267 124.791 98.1091 120.847Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5460" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5460)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M74.391 166.226C74.391 166.226 59.8586 144.662 55.1733 137.638C50.488 130.606 50.488 113.268 52.8263 108.106C55.1733 102.953 64.5439 93.5735 64.5439 93.5735C65.0116 89.3559 65.8322 43.4206 66.2998 31.7029C66.7674 19.9941 73.5704 20.4529 73.5704 20.4529C73.5704 20.4529 80.2498 19.4029 79.191 31.3412C78.141 43.2971 80.8321 90.3971 81.538 92.8588C81.538 92.8588 84.6969 94.6147 85.4027 91.1029C86.1086 87.5912 95.0116 30.2912 96.0704 25.3676C97.1204 20.4529 101.691 20.1 103.8 20.7971C105.909 21.5029 109.076 23.6029 107.673 34.5088C106.262 45.3971 103.685 76.6853 103.685 79.5C103.685 79.5 107.197 77.7441 109.314 78.8029C111.423 79.8529 115.994 85.8353 115.994 85.8353C115.994 85.8353 120.917 87.2118 124.429 88.9677C127.941 90.7235 133.57 100.95 133.217 108.679C132.864 116.409 120.564 156.132 120.203 161.409C120.203 161.409 98.4086 173.709 93.8469 175.121C89.2763 176.532 74.3998 166.218 74.3998 166.218L74.391 166.226Z" fill="#FFCEC9"/>
                                            <path d="M72.4061 158.956L69.7061 179.541L117.512 179.841L120.203 161.418L108.256 145.597L72.4061 158.956Z" fill="#FFCEC9"/>
                                            <path d="M64.5526 93.5735C64.5526 93.5735 64.0849 104.047 64.0849 114.829L61.1025 111.026L64.5526 93.5735Z" fill="#D77D79"/>
                                            <path d="M103.191 92.5411L103.686 79.5088C103.686 79.5088 106.738 89.8323 107.991 93.6882L103.191 92.5411Z" fill="#D77D79"/>
                                            <path d="M118.835 95.2589C118.368 92.4531 115.994 85.8442 115.994 85.8442C115.994 85.8442 121.756 94.4472 123.635 96.6178C123.635 96.6178 118.862 95.0207 118.835 95.2589Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5471" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5471)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M45.4147 128.418C48.1941 125.577 82.8971 118.853 82.8971 118.853L99.3088 112.535L99.2912 112.385C95.7971 112.359 92.7441 112.28 90.9618 112.165C85.8706 111.83 81.7059 108.547 80.7971 103.88C80.7971 103.88 79.7647 94.059 85.4559 93.5472C84.0883 93.3354 77.4177 89.859 77.2588 84.0354C77.2588 84.0354 79.8794 75.3796 85.9588 75.3884C87.4235 75.3884 90.5647 75.7943 94.4912 76.4119V76.3149L83.5412 74.9119C83.5412 74.9119 48.0882 69.459 39.4147 68.109C30.75 66.759 26.0471 66.9531 24.8559 62.5678C23.6735 58.1737 26.6735 53.9649 32.2853 53.9119C37.9059 53.859 84.8912 61.209 89.9824 61.5354C93.203 61.7472 101.806 62.2325 107.691 62.559C108.741 57.9796 109.447 54.5737 109.447 54.5737C109.447 54.5737 132.309 50.859 140.585 51.6884C146.912 52.3149 158.038 69.2296 162.953 77.1884L181.147 90.2649L181.324 134.744L155.453 119.974C152.541 122.48 147.865 125.541 141.494 126.609C129.979 128.55 105.679 128.48 96.653 129.406C87.6265 130.333 55.7559 139.835 48.8471 139.227C41.9383 138.609 42.6177 131.241 45.3971 128.409L45.4147 128.418Z" fill="#FFCEC9"/>
                                            <path d="M145.579 99.0973C145.253 94.8708 141.177 90.5208 141.177 90.5208C137.197 84.7061 129.335 71.8061 129.415 66.6355L122.797 66.512C122.435 69.3355 119.259 74.409 119.259 74.409L75.9001 72.9708L68.9824 72.6708C81.4412 74.5943 83.5412 74.9208 83.5412 74.9208L94.4824 76.3237V76.4208C90.5648 75.8031 87.4236 75.4061 85.9589 75.3973C79.8883 75.3884 77.2589 84.0443 77.2589 84.0443C77.4177 89.8678 84.0883 93.3443 85.4559 93.5561C84.2383 93.662 83.3207 94.209 82.6412 94.9855L118.932 111.627C119.894 111.512 121.518 111.247 122.506 111.124C134.435 109.641 145.897 103.324 145.571 99.1061L145.579 99.0973Z" fill="#D77D79"/>
                                            <path d="M77.2588 84.0356C77.4176 89.8591 84.0882 93.3356 85.4558 93.5473L87.0706 93.9532C88.4382 94.165 115.465 95.9032 123.856 95.4003C132.247 94.8973 132.274 91.5179 131.859 88.1826C131.859 88.1826 132.106 82.1385 124.315 81.1591L115.615 80.0297L103.323 77.9032C95.5588 76.5267 88.4206 75.3973 85.9588 75.3973C82.3411 75.3444 78.1058 78.8032 77.2588 84.0444V84.0356Z" fill="#FFCEC9"/>
                                            <path d="M131.859 88.1825C132.538 91.6148 132.185 95.0736 123.856 95.4001C115.456 95.7266 88.4382 94.156 87.0706 93.9531L85.4558 93.5472C84.0882 93.3354 77.4176 89.8589 77.2588 84.0354C77.2588 84.0354 79.297 89.5413 85.1117 90.2119C89.2235 90.6883 107.938 92.2325 109.959 92.2325C111.979 92.2325 124.085 92.5413 125.638 92.2325C127.191 91.9236 131.409 90.8119 131.868 88.1825H131.859Z" fill="#D77D79"/>
                                            <path d="M181.165 90.2736L162.971 77.1972C158.056 69.2295 146.929 52.3236 140.603 51.6972C132.318 50.8678 109.465 54.5825 109.465 54.5825C109.465 54.5825 108.759 57.9795 107.709 62.5678L107.691 62.6825C106.129 69.4766 103.835 78.8119 101.982 83.5237C101.982 83.5237 107.197 89.0119 113.885 82.1119C120.574 75.2119 122.797 66.5295 122.797 66.5295L129.415 66.6531C129.415 66.6531 132.185 81.706 139.077 88.3942C149.241 100.324 156.071 97.6854 160.262 98.1531C164.453 98.6207 181.165 90.2913 181.165 90.2913V90.2736Z" fill="#FFCEC9"/>
                                            <path d="M122.903 66.5032C122.903 66.5032 120.679 75.2297 113.991 82.1297C107.303 89.0296 101.982 83.5061 101.982 83.5061C107.162 83.6914 111.662 81.2032 113.374 79.0326C115.085 76.862 119.744 67.0767 119.744 64.4385C119.744 64.4385 121.332 66.2385 122.903 66.5032Z" fill="#D77D79"/>
                                            <path d="M99.3092 112.535L99.2915 112.385C95.7974 112.359 92.7444 112.279 90.9621 112.165C85.8709 111.829 81.7062 108.547 80.7974 103.879C80.365 95.8059 82.6239 93.7854 85.4562 93.5471L123.856 95.4001C125.718 96.0971 130.518 98.2501 129.733 103.271C128.947 108.291 124.8 110.912 118.121 111.741C111.45 112.562 96.0356 112.491 90.9533 112.165" fill="#FFCEC9"/>
                                            <path d="M99.291 112.385C95.7969 112.359 92.7439 112.28 90.9616 112.165C85.8704 111.83 81.7057 108.547 80.7969 103.88C84.5381 108.865 86.0822 108.706 91.0145 109.306C95.2233 109.827 112.906 109.615 112.906 109.615C118.182 109.306 123.812 108.344 126.415 106.509C129.017 104.674 129.741 103.262 129.741 103.262C129.123 108.565 124.826 111.018 118.129 111.733C111.45 112.438 96.0439 112.483 90.9616 112.156" fill="#D77D79"/>
                                            <path d="M99.3087 112.535L99.291 112.385L99.3087 112.535Z" fill="#FAB4AD"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_4142" style={{maskType:"alpha"}}np maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_4142)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M110.118 79.7206C110.118 79.7206 116.982 49.05 118.359 43.553C119.735 38.0559 125.224 38.9736 127.976 40.8088C130.721 42.6353 130.721 47.2236 128.885 55.4647C127.05 63.7059 124.129 88.553 123.071 98.1088C122.321 104.85 111.979 139.694 111.671 144.318L131.029 174L95.2147 186.424L71.1089 147.909C68.903 144.626 58.35 128.974 54.6883 123.468C50.5765 117.309 50.5765 102.115 52.6324 97.5971C54.6795 93.0794 62.8942 84.8647 62.8942 84.8647C63.3089 81.1588 64.0236 40.9059 64.4383 30.6441C64.853 20.3736 70.8089 20.7794 70.8089 20.7794C70.8089 20.7794 78.9089 19.3236 77.9824 29.7883V80.6118L84.8471 68.25C84.8471 68.25 93.5206 67.2 97.2 72.5383C97.2 72.5383 100.279 70.9941 102.124 71.9206C103.976 72.8471 110.109 79.7294 110.109 79.7294L110.118 79.7206Z" fill="#FFCEC9"/>
                                            <path d="M62.4882 113.603C62.4882 104.162 62.8941 84.856 62.8941 84.856C62.8941 84.856 59.6559 94.6412 59.4353 97.5177C59.2147 100.394 60.7676 110.197 62.4794 113.603H62.4882Z" fill="#D77D79"/>
                                            <path d="M99.2382 80.8325L95.3206 70.7207C95.3206 70.7207 96.4941 71.7089 97.2 72.5295L103.685 82.9413C103.685 82.9413 100.244 80.8236 99.2382 80.8236V80.8325Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="section2-mo">
                                <div className="copyDiv-mo">
                                    <p className="disc">수어를 알고 계신가요?<br/>수어는 30만명이 넘는 한국인이<br/>사용하는 제2의 국어입니다.<br/>하지만 수어를 인식하는 서비스는<br/>찾아보기 힘들죠.</p>
                                </div>                                
                                <div className="imgDiv imgDiv3"  style={{overflow:"hidden"}} >                                    
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5415" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5415)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M73.6056 160.138L65.0732 182.338H99.1938L111.326 161.576L73.6056 160.138Z" fill="#FFCEC9"/>
                                            <path d="M98.8502 143.691L89.0296 165.035L73.1384 165.512C73.1384 165.512 60.9796 133.88 59.109 122.188C57.2384 110.497 57.7061 92.2678 55.8355 83.8501C53.9649 75.4325 46.9502 52.059 46.9502 52.059C46.9502 52.059 52.0943 46.4472 56.3031 50.1884C60.512 53.9295 67.5267 82.4472 67.5267 82.4472C67.5267 82.4472 68.9296 87.5913 70.3325 88.059C71.7355 88.5266 72.6708 82.9237 71.2678 72.6354C69.8649 62.3472 67.5267 34.7648 67.059 32.4354C66.5914 30.0972 71.7355 28.2266 74.5414 30.0972C77.3473 31.9678 78.2825 36.1766 79.6855 49.2619C81.0884 62.356 83.4267 80.5766 83.4267 80.5766C83.4267 80.5766 85.7649 82.2972 86.7002 76.6854C87.6355 71.0825 91.8443 27.759 92.312 22.6148C92.312 22.6148 95.5855 18.406 98.3914 20.2766C101.197 22.1472 105.406 23.859 104.003 36.4854C102.6 49.1119 99.7943 77.1531 99.7943 77.1531C99.7943 77.1531 106.341 65.4707 108.68 64.0678C111.009 62.6648 123.168 73.8796 126.909 77.6296C126.909 77.6296 126.909 85.5796 128.78 89.3207C130.65 93.0619 124.103 95.5501 120.83 94.6237C117.556 93.6884 113.815 83.4001 113.815 83.4001L112.88 82.7825L106.333 93.5295C106.333 93.5295 107.735 108.027 110.074 113.171C112.412 118.315 117.556 120.644 117.556 120.644L99.7943 142.156L98.859 143.709L98.8502 143.691Z" fill="#FFCEC9"/>
                                            <path d="M140.93 107.074C140.93 107.074 132.98 97.4118 129.238 92.7353C125.497 88.0589 120.821 93.9795 121.756 99.5912C122.691 105.203 127.835 110.815 127.835 110.815C127.835 110.815 120.821 119.232 118.483 120.168C116.144 121.103 107.727 111.282 107.727 111.282L96.0443 117.979L82.9502 160.527L89.4973 166.138H108.194C108.194 166.138 122.868 143.877 127.827 138.397C133.906 131.7 145.588 113.153 140.921 107.074H140.93Z" fill="#FFCEC9"/>
                                            <path d="M123.971 101.268C123.045 99.0531 122.471 96.8384 122.25 94.8002C122.03 92.7619 122.25 94.8002 122.25 94.8002C122.25 94.8002 122.762 93.7678 122.25 94.8002C121.739 95.8325 121.615 97.2707 121.765 99.5913C122.7 105.203 127.845 110.815 127.845 110.815C127.845 110.815 120.83 119.233 118.492 120.168C119.859 119.885 119.709 121.183 122.109 119.338C124.509 117.494 130.783 109.924 130.783 109.924C128.453 108.433 124.898 103.483 123.971 101.268ZM124.439 93.8207C122.4 93.3796 117.733 87.5472 117.15 86.3825C116.568 85.2178 114.406 82.9149 114.406 82.9149L112.88 82.7737L113.815 83.3913C113.815 83.3913 117.556 93.6796 120.83 94.6149C121.253 94.7384 121.739 94.7913 122.25 94.8002L124.439 93.8207Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5425" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5425)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M67.8968 68.391C67.8968 68.391 74.9291 68.6028 88.985 69.4057C103.05 70.2086 148.359 66.7763 157.941 68.5498C157.941 68.5498 163.464 69.8292 163.641 76.1381C163.641 76.1381 164.585 81.7234 153.097 81.3704C141.617 81.0175 117.794 84.7322 110.709 86.0116C110.709 86.0116 115.694 92.8322 114.838 96.5116C113.982 100.191 105.741 103.27 105.741 103.27C105.741 103.27 109.279 108.565 108.423 112.244C107.567 115.923 100.05 119.4 100.05 119.4C100.05 119.4 102.864 124.306 102.017 127.985C101.161 131.665 91.4027 135.812 80.9733 137.347C70.5527 138.882 42.6527 130.253 28.685 126.485C28.685 126.485 17.0027 96.4763 16.7556 92.3998C16.5086 88.3145 28.8615 72.0175 30.0262 70.1998C31.1909 68.3822 41.5321 57.5822 51.7144 51.5998C61.9056 45.6086 64.7997 35.691 66.9086 27.2292C66.9086 27.2292 69.9615 24.3616 73.588 27.0616C77.2144 29.7616 79.4556 41.3204 71.7085 52.1998C71.7085 52.1998 63.988 62.3469 63.9086 64.9322C63.8291 67.5263 67.8791 68.391 67.8791 68.391H67.8968Z" fill="#FFCEC9"/>
                                            <path d="M31.6674 74.6028C31.6674 74.6028 6.56448 83.5322 -7.94141 85.3145L-2.49729 134.673C-2.49729 134.673 21.9792 127.95 30.9174 126.547C39.8557 125.162 44.8057 90.291 44.8057 90.291C44.8057 90.291 40.0409 73.7469 31.6674 74.594V74.6028Z" fill="#FFCEC9"/>
                                            <path d="M110.709 86.0117L101.78 87.2205L94.6943 88.2088C96.9002 90.4147 107.921 91.8794 113.48 90.9352C113.48 90.9352 112.244 88.1294 110.709 86.0117Z" fill="#D77D79"/>
                                            <path d="M105.741 103.271L96.4235 104.832L93.0176 105.3C93.0176 105.3 95.7705 107.859 97.1029 108.335C99.7588 109.571 104.506 109.094 108.212 108.053C108.212 108.053 106.606 104.276 105.75 103.271H105.741Z" fill="#D77D79"/>
                                            <path d="M100.059 119.4L93.3263 121.412L89.7881 122.576C91.4028 124.473 93.3969 125.329 94.8175 125.329C96.2381 125.329 99.4675 125.047 101.559 123.529C101.559 123.529 100.826 120.212 100.05 119.4H100.059Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5436" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5436)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M111.229 153.159C111.229 153.159 104.365 162.918 100.138 179.912L54.7764 178.668C54.7764 178.668 64.6322 168.459 65.7528 156.256C66.8822 144.053 72.2381 135.909 72.2381 135.909L111.238 153.159H111.229Z" fill="#FFCEC9"/>
                                            <path d="M110.25 159.706C110.25 159.706 97.8175 166.994 94.2528 167.691C90.6793 168.379 65.2852 158.603 65.2852 158.603C57.6969 147.741 56.5146 118.5 57.5205 110.629C58.5263 102.758 58.1293 74.0644 58.2881 58.8085C58.4381 43.5526 61.6146 42.8467 63.741 42.1232C65.8675 41.3909 69.2557 42.5379 69.2557 42.5379L69.3881 37.5438C69.2999 30.9614 72.8116 27.8997 76.7557 27.7409C80.7175 27.5909 82.1822 31.3144 82.1822 31.3144C82.641 24.2203 85.5793 22.7203 89.541 22.2967C93.5028 21.882 95.5234 24.8291 95.991 26.9467C96.4587 29.0644 97.0675 35.9203 97.0675 35.9203C97.4734 30.4056 102.485 30.0173 102.485 30.0173C108.009 30.1673 109.306 35.8585 109.306 35.8585C110.4 39.0438 111.891 56.4526 112.676 71.082C113.462 85.7114 114.282 99.1497 114.282 99.1497L123.926 121.65L110.25 159.697" fill="#FFCEC9"/>
                                            <path d="M131.197 103.729L101.7 87.8997C101.7 87.8997 97.4734 95.532 101.726 100.191C105.97 104.858 115.394 110.206 115.394 110.206L116.02 119.294C116.02 119.294 97.9499 128.85 92.8587 141.864C89.6028 147.697 86.2764 154.958 85.6675 157.685C85.0587 160.411 79.0058 176.153 76.2793 178.879L77.6558 178.95L77.4793 179.285L100.147 179.903C102.009 172.411 104.382 166.332 106.456 161.876C108.662 160.641 110.259 159.706 110.259 159.706C110.259 159.706 130.218 139.094 131.444 134.444C132.653 129.794 131.197 103.72 131.197 103.72V103.729Z" fill="#FFCEC9"/>
                                            <path d="M120.07 122.682L119.364 107.92C119.364 107.92 113.744 104.761 107.064 100.544C102.635 97.7467 100.826 93.3085 101.7 87.8997C101.7 87.8997 97.4732 95.532 101.726 100.191C105.97 104.858 115.394 110.206 115.394 110.206L116.02 119.294L120.079 122.682H120.07Z" fill="#D77D79"/>
                                            <path d="M97.0676 35.9292L96.2646 83.0821L99.3352 82.9674C99.1323 75.8557 97.0676 35.9292 97.0676 35.9292Z" fill="#D77D79"/>
                                            <path d="M82.1908 31.3145L81.6084 83.8409L84.7231 82.9586C84.6172 72.2909 82.1908 31.3145 82.1908 31.3145Z" fill="#D77D79"/>
                                            <path d="M69.2555 42.5378L68.0996 85.7467L71.6555 84.5731C71.532 74.6996 69.2555 42.5378 69.2555 42.5378Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5449" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5449)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M14.5331 77.6032C14.5331 77.6032 36.9448 62.4973 44.2595 57.6267C51.5742 52.7561 69.6007 52.7561 74.9566 55.1914C80.3125 57.6267 90.0625 67.3679 90.0625 67.3679C94.4566 67.862 142.201 68.7091 154.386 69.1944C166.562 69.6885 166.077 76.7473 166.077 76.7473C166.077 76.7473 167.18 83.6914 154.757 82.5973C142.324 81.5032 93.3536 84.3003 90.7948 85.0326C90.7948 85.0326 88.9684 88.3238 92.6213 89.0561C96.2742 89.7885 155.851 99.0444 160.96 100.138C166.068 101.233 166.439 105.98 165.707 108.177C164.974 110.374 162.786 113.665 151.448 112.2C140.127 110.744 107.604 108.062 104.674 108.062C104.674 108.062 106.501 111.715 105.398 113.912C104.312 116.1 98.0919 120.856 98.0919 120.856C98.0919 120.856 99.9184 125.974 98.0919 129.627C96.2654 133.28 82.3772 139.13 74.3389 138.759C66.3007 138.397 24.9978 125.603 19.5184 125.233C19.5184 125.233 6.73306 102.574 5.26835 97.8267C3.80365 93.0797 14.5242 77.6032 14.5242 77.6032H14.5331Z" fill="#FFCEC9"/>
                                            <path d="M22.0945 75.5298L-12.3525 85.7563L-6.13195 135.459L19.5357 125.224L35.9828 112.809L22.0945 75.5298Z" fill="#FFCEC9"/>
                                            <path d="M90.0619 67.3679C90.0619 67.3679 77.6824 66.6443 66.4766 66.6443C72.6972 65.6826 77.1001 64.8179 80.8324 64.959C84.5648 65.1002 86.3648 65.7884 90.0619 67.3767V67.3679Z" fill="#D77D79"/>
                                            <path d="M104.683 108.053L89.2242 107.056C89.2242 107.056 88.0684 110.091 88.3595 111.971C90.8213 112.112 94.8625 111.389 96.1684 111.247C97.4742 111.106 103.218 109.765 104.683 108.044V108.053Z" fill="#D77D79"/>
                                            <path d="M98.1091 120.847C98.1091 120.847 88.8267 123.927 85.9061 124.412C85.9061 124.412 85.1826 126.724 85.1826 127.88C88.3679 127.88 95.1267 124.791 98.1091 120.847Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="box2">
                                        <svg width="100%" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_582_5460" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="180">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#7661CC"/>
                                            </mask>
                                            <g mask="url(#mask0_582_5460)">
                                            <path d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z" fill="#9A8AD2"/>
                                            <path d="M74.391 166.226C74.391 166.226 59.8586 144.662 55.1733 137.638C50.488 130.606 50.488 113.268 52.8263 108.106C55.1733 102.953 64.5439 93.5735 64.5439 93.5735C65.0116 89.3559 65.8322 43.4206 66.2998 31.7029C66.7674 19.9941 73.5704 20.4529 73.5704 20.4529C73.5704 20.4529 80.2498 19.4029 79.191 31.3412C78.141 43.2971 80.8321 90.3971 81.538 92.8588C81.538 92.8588 84.6969 94.6147 85.4027 91.1029C86.1086 87.5912 95.0116 30.2912 96.0704 25.3676C97.1204 20.4529 101.691 20.1 103.8 20.7971C105.909 21.5029 109.076 23.6029 107.673 34.5088C106.262 45.3971 103.685 76.6853 103.685 79.5C103.685 79.5 107.197 77.7441 109.314 78.8029C111.423 79.8529 115.994 85.8353 115.994 85.8353C115.994 85.8353 120.917 87.2118 124.429 88.9677C127.941 90.7235 133.57 100.95 133.217 108.679C132.864 116.409 120.564 156.132 120.203 161.409C120.203 161.409 98.4086 173.709 93.8469 175.121C89.2763 176.532 74.3998 166.218 74.3998 166.218L74.391 166.226Z" fill="#FFCEC9"/>
                                            <path d="M72.4061 158.956L69.7061 179.541L117.512 179.841L120.203 161.418L108.256 145.597L72.4061 158.956Z" fill="#FFCEC9"/>
                                            <path d="M64.5526 93.5735C64.5526 93.5735 64.0849 104.047 64.0849 114.829L61.1025 111.026L64.5526 93.5735Z" fill="#D77D79"/>
                                            <path d="M103.191 92.5411L103.686 79.5088C103.686 79.5088 106.738 89.8323 107.991 93.6882L103.191 92.5411Z" fill="#D77D79"/>
                                            <path d="M118.835 95.2589C118.368 92.4531 115.994 85.8442 115.994 85.8442C115.994 85.8442 121.756 94.4472 123.635 96.6178C123.635 96.6178 118.862 95.0207 118.835 95.2589Z" fill="#D77D79"/>
                                            </g>
                                        </svg>
                                    </div>                                    
                                </div>
                                <div className="copyDiv2-mo"  id="fl2">
                                    <p className='disc2'>우리는 장애가 더이상<br/>차별이 아닌 세상을 꿈꿉니다.<br/>포스팟은 정확도 높은<br/>인공지능 수어 인식 기술로<br/>수어 사용자에게 더 넓은 세상을<br/>경험하게 할 것입니다.</p>
                                </div>
                            </div>
                            
                            <div className="section3"  id="txt4section">
                                <div className='boxDiv'>
                                    <div className="box left">
                                        <p className="main-title">포스팟은 기술로<br/>넓은 세상을 꿈꿉니다</p>
                                        <p className="sub-title">포스팟은 기술과 콘텐츠로<br/>새로운 생태계를 개발하는 스타트업입니다.</p>
                                    </div>
                                    <div className="box right">
                                        <div className="list">
                                            <div className="sec dt">
                                                <p className="date">2018.09</p>
                                            </div>
                                            <div className="sec">
                                                <svg className="circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2"/>
                                                </svg>
                                                <svg className="line" width="2" height="38" viewBox="0 0 2 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="2" height="38" fill="white" fillOpacity="0.2"/>
                                                </svg>
                                            </div>
                                            <div className="sec">
                                                <p className="desc">포스팟 설립, 장애인기업 등록</p>
                                            </div>
                                        </div>
                                        <div style={{float:"none", clear:"both"}}></div>
                                        <div className="list">
                                            <div className="sec dt">
                                                <p className="date">2018.11</p>
                                            </div>
                                            <div className="sec">
                                                <svg className="circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2"/>
                                                </svg>
                                                <svg className="line" width="2" height="38" viewBox="0 0 2 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="2" height="38" fill="white" fillOpacity="0.2"/>
                                                </svg>
                                            </div>
                                            <div className="sec">
                                                <p className="desc">포스팟 기업부설 연구소 인정</p>
                                            </div>
                                        </div>
                                        <div style={{float:"none", clear:"both"}}></div>
                                        <div className="list">
                                            <div className="sec dt">
                                                <p className="date">2019.04</p>
                                            </div>
                                            <div className="sec">
                                                <svg className="circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2"/>
                                                </svg>
                                                <svg className="line line40" width="2" height="72" viewBox="0 0 2 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="2" height="72" fill="white" fillOpacity="0.2"/>
                                                </svg>
                                            </div>
                                            <div className="sec mb15">
                                                <p className="desc">국내외 지식재산권 출원지원사업 선정<br/>장애인기업종합지원센터</p>
                                            </div>
                                        </div>
                                        <div style={{float:"none", clear:"both"}}></div>
                                        <div className="list">
                                            <div className="sec dt">
                                                <p className="date">2019.09</p>
                                            </div>
                                            <div className="sec">
                                                <svg className="circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2"/>
                                                </svg>
                                                <svg className="line line40" width="2" height="72" viewBox="0 0 2 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="2" height="72" fill="white" fillOpacity="0.2"/>
                                                </svg>
                                            </div>
                                            <div className="sec mb15">
                                                <p className="desc">중소벤처기업부 초기창업패키지 신사업분야<br/>‘간편인증 솔루션' 과제 선정</p>
                                            </div>
                                        </div>
                                        <div style={{float:"none", clear:"both"}}></div>
                                        <div className="list">
                                            <div className="sec dt">
                                                <p className="date">2020.10</p>
                                            </div>
                                            <div className="sec">
                                                <svg className="circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2"/>
                                                </svg>
                                                <svg className="line" width="2" height="38" viewBox="0 0 2 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="2" height="38" fill="white" fillOpacity="0.2"/>
                                                </svg>
                                            </div>
                                            <div className="sec">
                                                <p className="desc">자체 통합인증솔루션 OAuthumb GS인증 계약</p>
                                            </div>
                                        </div>
                                        <div style={{float:"none", clear:"both"}}></div>
                                        <div className="list">
                                            <div className="sec dt">
                                                <p className="date">2021.04</p>
                                            </div>
                                            <div className="sec">
                                                <svg className="circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2"/>
                                                </svg>
                                                <svg className="line" width="2" height="38" viewBox="0 0 2 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="2" height="38" fill="white" fillOpacity="0.2"/>
                                                </svg>
                                            </div>
                                            <div className="sec">
                                                <p className="desc">강소형 기술기업 프로젝트 융성지원 사업자 선정</p>
                                            </div>
                                        </div>
                                        <div style={{float:"none", clear:"both"}}></div>
                                        <div className="list">
                                            <div className="sec dt">
                                                <p className="date">2021.06</p>
                                            </div>
                                            <div className="sec">
                                                <svg className="circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2"/>
                                                </svg>
                                                <svg className="line" width="2" height="38" viewBox="0 0 2 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="2" height="38" fill="white" fillOpacity="0.2"/>
                                                </svg>
                                            </div>
                                            <div className="sec">
                                                <p className="desc">자본금 증자 5억원</p>
                                            </div>
                                        </div>
                                        <div style={{float:"none", clear:"both"}}></div>
                                        <div className="list">
                                            <div className="sec dt">
                                                <p className="date">2021.11</p>
                                            </div>
                                            <div className="sec">
                                                <svg className="circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2"/>
                                                </svg>
                                                <svg className="line" width="2" height="38" viewBox="0 0 2 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="2" height="38" fill="white" fillOpacity="0.2"/>
                                                </svg>
                                            </div>
                                            <div className="sec">
                                                <p className="desc">(주)포스팟 주관 경기청년투자경진대회 개최</p>
                                            </div>
                                        </div>
                                        <div style={{float:"none", clear:"both"}}></div>
                                        <div className="list">
                                            <div className="sec dt">
                                                <p className="date">2021.12</p>
                                            </div>
                                            <div className="sec">
                                                <svg className="circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2"/>
                                                </svg>
                                                <svg className="line" width="2" height="38" viewBox="0 0 2 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="2" height="38" fill="white" fillOpacity="0.2"/>
                                                </svg>
                                            </div>
                                            <div className="sec">
                                                <p className="desc">안양시 유망창업기업 선정</p>
                                            </div>
                                        </div>
                                        <div style={{float:"none", clear:"both"}}></div>
                                        <div className="list">
                                            <div className="sec dt">
                                                <p className="date">2022.05</p>
                                            </div>
                                            <div className="sec">
                                                <svg className="circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2"/>
                                                </svg>
                                            </div>
                                            <div className="sec">
                                                <p className="desc">신진 연구인력 채용지원사업 선정<br/>창업성장기술개발사업 선정 (중소기업 기술정보진흥원)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="section4"  id="txt5section">
                                <p className="main-title">포스팟은 자유롭고 창의적인 문화를 위해<br/>지속적인 혁신을 추구합니다.</p>
                                <div className="list">
                                    <div className="list-inner">
                                        <div className="box">
                                            <p className="desc">포스팟에서는 누구나<br/>리더가 될 수 있습니다.</p>
                                            <svg width="200" height="280" viewBox="0 0 200 280" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_487_1306)">
                                                <path d="M74.875 80.9273C59.6889 75.4436 42.3909 75.0202 32.2651 80.9273C22.1394 86.8345 4.84139 107.086 1.0461 125.226C-2.74919 143.365 3.99966 174.166 17.9207 193.571C31.8418 212.976 53.3584 231.12 67.2844 236.604C81.2105 242.088 89.2244 241.665 92.178 241.665H121.709C121.709 241.665 169.802 213.817 180.351 200.32C190.9 186.817 205.245 162.347 198.073 132.393C190.9 102.439 136.053 96.5319 125.509 94.8434C114.965 93.155 84.164 85.5594 74.88 80.9224L74.875 80.9273Z" fill="#EAECF9"/>
                                                <path d="M113.411 146.463L101.597 129.026L88.9409 141.119L55.7496 100.337L0.0205078 137.921C0.359195 155.643 6.88391 178.185 17.9211 193.576C31.8422 212.98 53.3588 231.125 67.2849 236.609C81.2059 242.093 89.2248 241.669 92.1784 241.669H121.709C121.709 241.669 169.803 213.822 180.352 200.324C182.732 197.276 185.307 193.665 187.788 189.561L134.504 125.37L113.411 146.463Z" fill="#D8DBFD"/>
                                                <path d="M113.411 146.463L101.597 129.026L88.9411 141.119L55.7498 100.337L25.0886 121.017C25.0537 126.476 26.3686 131.347 30.1539 132.682C34.9354 134.37 41.4053 132.682 44.7773 130.714C48.1542 128.747 50.1216 125.933 53.4985 125.933C56.8754 125.933 62.7825 129.589 65.3127 131.277C67.8429 132.965 82.4712 143.37 83.313 144.217C84.1547 145.059 89.7829 146.184 91.4713 145.059C93.1598 143.933 98.5041 138.589 100.193 137.747C101.881 136.905 104.974 137.184 106.662 140.277C108.351 143.37 113.695 149.84 113.695 149.84C113.695 149.84 128.319 138.31 130.291 137.184C132.258 136.058 136.477 136.342 139.291 138.31C140.173 138.927 146.269 143.345 154.213 149.113L134.51 125.375L113.416 146.468L113.411 146.463Z" fill="white"/>
                                                <path d="M42.4362 173.972C40.0504 171.447 37.2463 169.883 31.2197 169.947C31.2695 169.718 31.3043 169.484 31.3043 169.24C31.3043 166.82 28.6197 164.862 25.3126 164.862C23.9628 164.862 22.7176 165.191 21.7165 165.744C21.6418 162.85 19.0319 160.524 15.8193 160.524C12.6068 160.524 9.91223 162.915 9.91223 165.868C9.91223 166.272 9.96702 166.66 10.0567 167.034C9.36436 166.879 8.61725 166.79 7.83528 166.79C6.86404 166.79 5.95258 166.924 5.13574 167.153C5.86292 169.444 6.66482 171.721 7.54142 173.957C7.64103 173.957 7.73567 173.967 7.83528 173.967C7.83528 173.967 42.2967 173.822 42.4412 173.967L42.4362 173.972Z" fill="white"/>
                                                <path d="M72.0364 218.116C68.5649 214.44 64.4807 212.169 55.7097 212.258C55.7844 211.925 55.8342 211.581 55.8342 211.232C55.8342 207.711 51.9294 204.857 47.113 204.857C45.1456 204.857 43.3377 205.34 41.8783 206.142C41.7737 201.928 37.9734 198.542 33.2916 198.542C29.8051 198.542 26.8117 200.424 25.4619 203.124C30.0342 208.448 35.0149 213.508 40.0554 218.051C54.3948 218.011 71.9169 217.991 72.0414 218.111L72.0364 218.116Z" fill="white"/>
                                                <path d="M7.39692 144.102C9.26469 141.806 11.8497 138.215 10.6991 136.347C9.9321 135.102 8.19882 134.624 5.42457 134.271L0.0205078 137.921C0.0952183 141.811 0.46379 145.93 1.12124 150.164C2.38634 149.063 5.88777 145.97 7.4019 144.107L7.39692 144.102Z" fill="white"/>
                                                <path d="M146.988 120.702C148.532 113.173 144.266 105.938 137.459 104.542C130.653 103.146 123.883 108.118 122.339 115.646C120.795 123.175 125.061 130.41 131.868 131.806C138.674 133.202 145.444 128.231 146.988 120.702Z" fill="#FAB4AD"/>
                                                <path d="M125.977 113.97V114.966C125.977 114.966 121.414 115.962 118.426 115.962C116.085 115.962 113.435 115.658 110.721 114.806L87.0474 172.244C87.0474 172.244 89.0397 175.73 78.0822 175.73C67.1246 175.73 61.6459 171.746 59.6536 168.259C59.6536 168.259 57.1632 163.278 62.642 156.306L102.986 109.985C104.978 107.993 108.464 106.997 111.453 106.997C114.441 106.997 127.142 108.242 127.142 108.242L125.399 112.475C123.342 112.241 119.213 112.261 118.067 112.924C118.999 113.467 124.751 114.044 125.977 113.97Z" fill="#FBB5AE"/>
                                                <path d="M128.621 130.605V137.738V140.327H137.666H142.662V126.67L128.621 130.605Z" fill="#FBB5AE"/>
                                                <path d="M171.969 189.925L177.946 199.887L180.934 235.25L178.16 241.859H187.588L197.864 199.887C198.86 195.404 197.669 191.917 197.017 189.925C196.369 187.933 193.381 182.454 193.381 182.454L171.964 189.925H171.969Z" fill="#FBB5AE"/>
                                                <path d="M92.3615 140.512C99.3893 139.959 116.03 138.48 122.131 139.586L128.048 136.996C130.638 139.401 134.518 140.323 136.74 140.323C138.961 140.323 146.542 139.581 149.127 138.843C155.412 140.507 162.809 143.834 169.283 149.014C175.753 154.194 187.592 166.397 193.136 173.977C198.685 181.558 199.795 184.148 199.795 184.148C200.537 186.18 199.053 186.922 197.021 188.217L194.431 184.706C189.251 184.89 175.201 188.217 172.242 190.807C172.242 190.807 173.537 206.71 173.906 212.075C174.274 217.439 176.68 239.996 177.048 241.66H96.7993C98.0943 230.194 99.9421 211.701 100.311 207.637C100.679 203.573 102.159 176.757 102.159 175.093C102.159 175.093 86.808 178.604 78.8588 178.978C77.7482 173.987 79.2274 163.817 81.2645 157.342C83.2967 150.872 88.8451 143.844 92.3615 140.512Z" fill="#148664"/>
                                                <path d="M156.528 145.597C150.551 148.585 135.584 147.589 132.854 145.597L136.605 121.69C139.095 119.697 139.145 115.972 138.597 114.717C138.154 113.696 137.158 112.819 135.639 112.774C134.12 112.729 132.063 114.124 131.888 116.714L130.085 118.178C127.64 112.724 127.68 107.041 127.495 104.636C127.311 102.23 128.77 100.975 130.628 100.771C136.107 99.7746 143.578 99.7746 149.057 102.265C152.996 104.466 154.784 108.541 154.849 112.361C154.914 116.181 154.232 118.467 154.107 120.315C152.787 126.466 155.86 143.55 156.528 145.592V145.597Z" fill="#23130F"/>
                                                <path d="M199.979 184.95C198.271 184.427 196.378 184.526 194.421 184.686C195.148 185.687 195.9 187.281 196.428 188.451C196.627 188.401 196.891 188.277 197.026 188.222C198.356 187.6 200.099 186.409 199.979 184.95Z" fill="#23130F"/>
                                                <path d="M55.0967 39H55.4703C55.8837 39 56.2174 39.3337 56.2174 39.7471V102.18H54.3496V39.7471C54.3496 39.3337 54.6833 39 55.0967 39Z" fill="#23130F"/>
                                                <path d="M51.9092 46.3216C54.783 43.881 61.5319 42.7305 65.9797 42.7305C70.4274 42.7305 75.0246 44.1649 77.8985 47.4671C80.9118 51.7754 81.9179 55.506 86.5151 55.9393C91.1123 56.3726 100.586 52.6371 103.604 51.3421C106.622 50.0471 113.227 48.1843 118.825 49.046C111.503 51.631 105.472 57.5182 105.183 60.3871C109.635 57.8021 118.536 57.5132 120.548 57.9465C115.812 59.9587 107.195 63.9781 103.604 67.2803C100.013 70.5825 97.4278 74.602 90.8234 74.7464C84.219 74.8909 81.3451 73.7403 78.7601 72.3059C76.1751 70.8714 75.0246 65.8459 70.7213 65.4126C66.418 64.9793 60.6702 67.5642 57.2236 69.1481C53.7769 70.732 52.4869 70.4381 51.9092 69.0037C51.9092 69.0037 51.9092 46.1721 51.9092 46.3166V46.3216Z" fill="#F2614E"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_487_1306">
                                                <rect width="200" height="202.864" fill="white" transform="translate(0 39)"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="box">
                                            <p className="desc">창의적 인재 육성을 위해<br/>주 4일제를 시행합니다.</p>
                                            <svg width="200" height="280" viewBox="0 0 200 280" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_487_1312)">
                                                <path d="M174.322 199.263V169.604C174.322 134.621 167.223 108 162.328 108H162.309C157.414 108 150.315 134.621 150.315 169.604V199.263H174.315" fill="#148664"/>
                                                <path d="M162.638 198.044L160.345 174.448C157.636 146.615 149.198 126.057 144.79 126.486H144.777C140.368 126.916 136.048 148.718 138.752 176.545L141.044 200.141L162.638 198.044Z" fill="#7EAE7E"/>
                                                <path d="M132 195.158H180V236.842C180 240.328 177.171 243.158 173.684 243.158H138.316C134.829 243.158 132 240.328 132 236.842V195.158Z" fill="#523AB1"/>
                                                </g>
                                                <g clipPath="url(#clip1_487_1312)">
                                                <path d="M118 47.7998H20V156.689H118V47.7998Z" fill="#D8DBFD"/>
                                                <path d="M110.922 128.378H95.6777V136H110.922V128.378Z" fill="white"/>
                                                <path d="M88.056 81.0112H72.8115V88.6334H88.056V81.0112Z" fill="white"/>
                                                <path d="M88.056 96.8003H72.8115V104.423H88.056V96.8003Z" fill="white"/>
                                                <path d="M88.056 112.589H72.8115V120.211H88.056V112.589Z" fill="white"/>
                                                <path d="M88.056 128.378H72.8115V136H88.056V128.378Z" fill="white"/>
                                                <path d="M110.922 81.0112H95.6777V88.6334H110.922V81.0112Z" fill="white"/>
                                                <path d="M110.922 96.8003H95.6777V104.423H110.922V96.8003Z" fill="white"/>
                                                <path d="M110.922 112.589H95.6777V120.211H110.922V112.589Z" fill="white"/>
                                                <path d="M56.4778 55.4224H44.5V64.1335H56.4778V55.4224Z" fill="white"/>
                                                <path d="M93.5002 55.4224H81.5225V64.1335H93.5002V55.4224Z" fill="white"/>
                                                <path d="M50.7615 38H50.2171C48.5633 38 47.2227 39.3407 47.2227 40.9944V57.8722C47.2227 59.526 48.5633 60.8667 50.2171 60.8667H50.7615C52.4153 60.8667 53.756 59.526 53.756 57.8722V40.9944C53.756 39.3407 52.4153 38 50.7615 38Z" fill="#F2614E"/>
                                                <path d="M87.784 38H87.2396C85.5858 38 84.2451 39.3407 84.2451 40.9944V57.8722C84.2451 59.526 85.5858 60.8667 87.2396 60.8667H87.784C89.4378 60.8667 90.7785 59.526 90.7785 57.8722V40.9944C90.7785 39.3407 89.4378 38 87.784 38Z" fill="#F2614E"/>
                                                <path d="M55.0679 96.027H58.2202V101.226H55.0679V106.6H48.7578V101.226H36.1484V96.86L46.1281 80.4668H55.0679V96.027ZM48.7578 96.027V85.7316L42.7634 96.027H48.7578Z" fill="#523AB1"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_487_1312">
                                                <rect width="48" height="135.158" fill="white" transform="translate(132 108)"/>
                                                </clipPath>
                                                <clipPath id="clip1_487_1312">
                                                <rect width="98" height="118.689" fill="white" transform="translate(20 38)"/>
                                                </clipPath>
                                                </defs>
                                            </svg>                                    
                                        </div>
                                        <div className="box">
                                            <p className="desc">출근과 재택<br/>근무 방식이 유연합니다.</p>
                                            <svg width="200" height="280" viewBox="0 0 200 280" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_487_1319)">
                                                <path d="M72.6339 179.428V132.809C72.6339 132.809 63.9184 134.628 58.4395 132.936V146.121C58.4395 152.91 60.1876 157.66 61.4822 161.544C62.7768 165.428 70.1161 180.412 70.1161 180.412L76.7316 179.428H72.6339Z" fill="#FAB4AD"/>
                                                <path d="M129.365 132.809V179.428H125.268L131.883 180.412C131.883 180.412 139.222 165.428 140.517 161.544C141.812 157.66 143.56 152.91 143.56 146.121V132.936C138.081 134.628 129.365 132.809 129.365 132.809Z" fill="#FAB4AD"/>
                                                <path d="M129.346 179.428V132.809C129.346 132.809 138.061 134.628 143.54 132.936V146.121C143.54 152.91 141.792 157.66 140.497 161.544C139.203 165.428 131.864 180.412 131.864 180.412L125.248 179.428H129.346Z" fill="#FAB4AD"/>
                                                <path d="M72.6192 132.809V179.428H76.717L70.1014 180.412C70.1014 180.412 62.7621 165.428 61.4676 161.544C60.173 157.66 58.4248 152.91 58.4248 146.121V132.936C63.9038 134.628 72.6192 132.809 72.6192 132.809Z" fill="#FAB4AD"/>
                                                <path d="M111.66 97.8961C123.22 99.9348 135.865 106.295 140.441 111.148C143.617 114.517 146.18 116.754 146.772 121.851C147.363 126.947 146.772 139.18 146.772 139.18C142.776 143.767 129.346 140.963 129.346 140.963V187.583H72.6192V140.963C72.6192 140.963 59.1893 143.767 55.1934 139.18C55.1934 139.18 54.6022 126.947 55.1934 121.851C55.7847 116.754 58.3483 114.511 61.5236 111.148C66.1004 106.295 78.7455 99.9348 90.3049 97.8961C93.1386 97.1825 109.331 97.4323 111.655 97.8961H111.66Z" fill="#FFBE00"/>
                                                <path d="M100.896 87.3306C110.486 87.3306 118.26 79.4216 118.26 69.6653C118.26 59.909 110.486 52 100.896 52C91.3056 52 83.5312 59.909 83.5312 69.6653C83.5312 79.4216 91.3056 87.3306 100.896 87.3306Z" fill="#23130F"/>
                                                <path d="M100.992 92.4884C110.177 92.4884 117.623 85.0426 117.623 75.8577C117.623 66.6728 110.177 59.2271 100.992 59.2271C91.8071 59.2271 84.3613 66.6728 84.3613 75.8577C84.3613 85.0426 91.8071 92.4884 100.992 92.4884Z" fill="#FAB4AD"/>
                                                <path d="M59.6472 205.401C50.5037 205.401 41.6354 204.275 33.8985 205.401C26.1617 206.528 26.6408 211.365 29.4236 216.135C32.2064 220.906 43.7403 222.098 48.1133 222.098H60.6768L59.6421 205.396L59.6472 205.401Z" fill="#FAB4AD"/>
                                                <path d="M60.8807 226.003L164.146 207.823C172.897 205.978 178.498 197.395 176.653 188.644C174.808 179.892 166.225 174.291 157.474 176.136L59.6523 201.742L60.8858 226.008L60.8807 226.003Z" fill="#523AB1"/>
                                                <path d="M139.565 181.304H64.291V202.619H139.565V181.304Z" fill="#523AB1"/>
                                                <path d="M141.124 226.003L37.8589 207.823C29.1078 205.978 23.5065 197.395 25.3515 188.644C27.1965 179.892 35.7794 174.291 44.5305 176.136L142.352 201.742L141.119 226.008L141.124 226.003Z" fill="#7661CC"/>
                                                <path d="M142.352 205.401C151.495 205.401 160.364 204.275 168.101 205.401C175.837 206.528 175.358 211.365 172.575 216.135C169.793 220.906 158.259 222.098 153.886 222.098H141.322L142.357 205.396L142.352 205.401Z" fill="#FAB4AD"/>
                                                <path d="M135.987 183.659L138.974 137.319H63.0322L66.0138 183.659H135.987Z" fill="#D8DBFD"/>
                                                <path d="M136.858 180.065H65.1423C64.2978 180.065 63.6133 180.75 63.6133 181.594V182.267C63.6133 183.112 64.2978 183.796 65.1423 183.796H136.858C137.703 183.796 138.388 183.112 138.388 182.267V181.594C138.388 180.75 137.703 180.065 136.858 180.065Z" fill="#B3BDF3"/>
                                                <path d="M101.003 165.469C103.753 165.469 105.982 163.239 105.982 160.489C105.982 157.739 103.753 155.51 101.003 155.51C98.2528 155.51 96.0234 157.739 96.0234 160.489C96.0234 163.239 98.2528 165.469 101.003 165.469Z" fill="#B3BDF3"/>
                                                <path d="M85.4828 74.5885C82.6286 71.0157 81.0027 73.9412 81.2066 77.1879C81.4105 80.4345 84.2595 83.36 86.0944 83.0338C87.9292 82.7076 85.4828 74.5885 85.4828 74.5885Z" fill="#FAB4AD"/>
                                                <path d="M100.992 92.4884C110.177 92.4884 117.623 85.0426 117.623 75.8577C117.623 66.6728 110.177 59.2271 100.992 59.2271C91.8071 59.2271 84.3613 66.6728 84.3613 75.8577C84.3613 85.0426 91.8071 92.4884 100.992 92.4884Z" fill="#FAB4AD"/>
                                                <path d="M116.497 74.5885C119.351 71.0157 120.977 73.9412 120.773 77.1879C120.569 80.4345 117.72 83.36 115.885 83.0338C114.051 82.7076 116.497 74.5885 116.497 74.5885Z" fill="#FAB4AD"/>
                                                <path d="M117.496 66.6736C117.496 58.5749 110.111 52.0103 101.003 52.0103C91.8952 52.0103 84.5049 58.5749 84.5049 66.6736C92.9808 71.0262 109.02 71.2555 117.496 66.6736Z" fill="#23130F"/>
                                                <path d="M101.074 102.193C97.4347 102.182 93.7396 101.438 91.5684 99.9348C92.7457 97.7941 92.2513 90.6383 91.5684 86.459H110.406C109.723 90.6383 109.229 97.7941 110.406 99.9348C108.24 101.438 104.545 102.182 100.9 102.193H101.069H101.074Z" fill="#FAB4AD"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_487_1319">
                                                <rect width="152" height="174.003" fill="white" transform="translate(25 52)"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="box">
                                            <p className="desc" style={{marginRight:"10px"}}>강요와 위계를 거부하며<br/>공식적인 회식이 없습니다.</p>
                                            <svg width="200" height="280" viewBox="0 0 200 280" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_487_1325)">
                                                <path d="M99.4243 153.563L133.238 86.606H65.7061L99.4243 153.563Z" fill="#7661CC"/>
                                                <path d="M47 49.4595L65.7065 86.6056H133.239L152 49.4595H47Z" fill="#D8DBFD"/>
                                                <path d="M101.279 214.076V136.533H97.7209V214.076H67.4648V217.633H131.535V214.076H101.279Z" fill="#7661CC"/>
                                                <path d="M119.273 37C112.062 37 106.123 42.4532 105.35 49.4596H133.197C132.417 42.4532 126.485 37 119.273 37Z" fill="#F2614E"/>
                                                <path d="M119.274 65.0323C127.012 65.0323 133.286 58.758 133.286 51.0195C133.286 50.4926 133.252 49.9726 133.197 49.4595H105.35C105.295 49.9726 105.261 50.4926 105.261 51.0195C105.261 58.758 111.535 65.0323 119.274 65.0323Z" fill="#B3BDF3"/>
                                                <path d="M99.4728 130.437C104.88 130.437 109.264 126.053 109.264 120.646C109.264 115.238 104.88 110.854 99.4728 110.854C94.0653 110.854 89.6816 115.238 89.6816 120.646C89.6816 126.053 94.0653 130.437 99.4728 130.437Z" fill="#523AB1"/>
                                                <path d="M91.7754 88.6586C91.7754 95.836 97.5981 101.659 104.776 101.659C111.953 101.659 117.776 95.836 117.776 88.6586C117.776 87.9607 117.707 87.2765 117.598 86.606H91.9533C91.8438 87.2765 91.7754 87.9607 91.7754 88.6586Z" fill="#523AB1"/>
                                                <path d="M104.775 75.6582C98.2958 75.6582 92.9384 80.4067 91.9531 86.6057H117.598C116.612 80.4067 111.255 75.6582 104.775 75.6582Z" fill="#B3BDF3"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_487_1325">
                                                <rect width="105" height="180.633" fill="white" transform="translate(47 37)"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section5"   id="txt6section">
                                <div className="text">
                                    <p className="main-title">포스팟 로그</p>
                                    <Link className='goLog' to='/log'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="15.5" transform="matrix(-1 0 0 1 16 16)" stroke="#444444"/>
                                            <path d="M17.3636 12L22 16.5M22 16.5L17.3636 21M22 16.5H10" stroke="#444444" strokeWidth="2" strokeLinecap="square"/>
                                        </svg>
                                    </Link>
                                </div>
                                <div className="img">
                                    <ul className="content-list">     
                                    {/* main에서 포스팟로그 리스트 세개만 보여주기 22.05.08 은정*/}
                                    {getLogList.map((data, index) => (
                                       
                                            index >= 3 ?
                                            null
                                            :
                                            <li className="content-box" key={"main-content"+index} >
                                            <div className="content-pic">
                                            {/* <div className="content-pic" onClick={()=>this.openPop(data)}> */}
                                                <div className="pic"><img key={"content-pic"+index} src={config.IMG_PATH+data.img_path1} onClick={()=>openPop(data)}/></div>
                                                {
                                                    {
                                                        news : <div className="icon-rt"><News/></div>,
                                                        insight : <div className="icon-rt"><Insight/></div>,
                                                        square : <div className="icon-rt"><Square/></div>,
                                                        people : <div className="icon-rt"><People/></div>
                                                    }[data.category_id]
                                                }
                                            </div>
                                            <div className="content-title">
                                                <p key={"content-title"+index} className="main-title">{data.title}</p>
                                                <p key={"content-date"+index} className="info">{moment(data.create_date).format('YYYY.M.DD')}<span>|</span>{data.category_name}</p>
                                            </div>
                                        </li>
                                        ))
                                    }

                                    </ul>

                                    {/* 대웅 */}

                                     {/* mobile 로그list */}
                                     <ul className="content-list-mo">                                    
                                     {
                                        getLogList.map((data, index) => (
                                            index >= 2 ?
                                            null
                                            :
                                            <li className="content-box" key={"content-box_mo"+index}>
                                            <div className="content-pic" onClick={()=>{
                                                openPop(data);
                                                setCopyIndex(index);}}>
                                                <div className="pic"><img key={"content-pic"+index} src={config.IMG_PATH+data.img_path1}/></div>
                                                {
                                                    {
                                                        news : <div className={index%2 ? `icon-ld`: `icon-rt`}><News/></div>,
                                                        insight : <div className={index%2 ? `icon-ld`: `icon-rt`}><Insight/></div>,
                                                        square : <div className={index%2 ? `icon-ld`: `icon-rt`}><Square/></div>,
                                                        people : <div className={index%2 ? `icon-ld`: `icon-rt`}><People/></div>
                                                    }[data.category_id]
                                                }
                                            </div>
                                            <div className="content-title">
                                                <p key={"main-title"+index} className="main-title">{data.title}</p>
                                                <p key={"main-content"+index} className="main-content">{data.content}</p>
                                                <p key={"main-date"+index} className="info">{moment(data.create_date).format('YYYY.M.DD')}<span>|</span>{data.category_name}</p>
                                                <div className="content-logo">
                                                    {data.link !== null ?
                                                    <div className='linkIcon' onClick={()=>viewlink2("copytxt2"+index, data.link)} ><Share/></div>
                                                    : 
                                                    null }
                                                    {
                                                        data.blog_link === 1 && <a href="https://blog.naver.com/pospot0911" target={"_blank"}><Blog/></a>
                                                    }
                                                    {
                                                        data.facebook_link === 1 && <a href="https://www.facebook.com/pospot.kr" target={"_blank"}><Facebook/></a>
                                                    }
                                                    {
                                                        data.instagram_link === 1 && <a href="https://www.instagram.com/pospot_official" target={"_blank"}><Insta/></a>
                                                    }

                                                   
                                                    <div className="arrow3" id={"copytxt2"+index}>
                                                        <div className="window">
                                                            <div className="arrow-box3">
                                                                <p>콘텐츠 링크가 복사되었어요.<br/>원하는 곳에 붙여넣으세요.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </li>
                                        ))
                                    }
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                    {
                    popupShow && popData != null 
                    ?
                    <Log />
                    :null
                    }
            </div>

            </Fade>

        )
    
}

export default Main;