import React from "react";

const NotFound=()=>{
    return(
        <div style={{
            height:"600px",
            marginTop:"300px",
            fontSize:"20pt",
            textAlign: "center"
        }}>
        페이지 값을 확인해주세요
        </div>
    )
}
export default NotFound;